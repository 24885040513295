import { Link, useNavigate } from "react-router-dom";
import CategoryList from "../../components/CategoryList/CategoryList";
import { useEffect, useMemo, useState } from "react";
import {
  deleteCategory,
  getCategoryListData,
} from "../../services/categories.service";
import { CustomError } from "../../interfaces/categories.interface";
import Button from "../../atoms/Button/Button";
import CustomModal from "../../components/CustomModal/CustomModal";
import Confirmation from "../../components/Confirmation/Confirmation";
import { toast } from "react-toastify";
import { CATEGORIES_HEADINGS } from "../../components/CategoryList/categoryList.constants";
import { TableDataType } from "../../interfaces/interface";
import { ActionType } from "../../interfaces/interface";
import style from "./Categories.module.scss";
import TableLoader from "../../components/Table/TableLoader";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import { StorageEnums } from "../../enums/storageEnums";

const Categories = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<string>("");
  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);

  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const handleDelete = async (data: any) => {
    setConfirmationModalVisible(true);
    setDeleteId(data.id);
  };

  const handleEdit = (rowData: any) => {
    navigate(`edit/${rowData?.id}`);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setConfirmationModalVisible(false);
    try {
      setLoading(true);
      await deleteCategory(deleteId, localStorageUser);
      toast.success("Category deleted successfully");
      getCategoryTableData();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const categoryListHeaders = useMemo(() => {
    return CATEGORIES_HEADINGS.map((el: any) => {
      if (el.type === TableDataType.ACTIONS) {
        el?.actionType?.forEach((e: any) => {
          if (e.name === ActionType.DELETE) {
            e.onClick = handleDelete;
          }
          if (e.name === ActionType.EDIT) {
            e.onClick = handleEdit;
          }
        });
      }
      return el;
    });
  }, []);

  const getCategoryTableData = async () => {
    try {
      setLoading(true);
      const response = await getCategoryListData(localStorageUser);
      response?.data?.result.map((item: any) => {
        if (item["icon"]) {
          item["icon"] = (
            <img
              src={`${item["icon"]}`}
              alt="Icon"
              width="30px"
              height="30px"
            />
          );
        }
        return item;
      });
      setTableData(response?.data?.result);
      setCount(response?.data?.count);
    } catch (error) {
      console.error(
        "error in getting categories table data",
        (error as CustomError)?.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategoryTableData();
  }, []);

  return (
    <>
      <div className={style.categories}>
        <div
          className={`${style.categories__header} flex-wrap align-center justify-between`}
        >
          <div>
            <h2>Categories</h2>
            {loading ? (
              " "
            ) : (
              <div className={style.categories__count}>
                {count > 0 && (
                  <p>
                    {count} {count > 1 ? "entries" : "entry"} found
                  </p>
                )}
              </div>
            )}
          </div>

          <Link to="create">
            <span>+ Add</span>
            <Button buttonClass="md" label="+ Add New Category" />
          </Link>
        </div>

        {loading ? (
          <>
            <Skeleton height={"10px"} width="100px" margin="0 0 10px" />
            <TableLoader rows={10} columns={4} headings={CATEGORIES_HEADINGS} />
          </>
        ) : (
          <CategoryList
            tableData={tableData}
            categoryListHeaders={categoryListHeaders}
          />
        )}
      </div>

      <CustomModal
        maxWidth="480px"
        title=""
        modalVisible={isConfirmationModalVisible}
        onClose={handleCloseConfirmationModal}
        footerNode={
          <>
            <Button
              buttonClass="md outline"
              onClick={handleCloseConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              buttonClass="md"
              loading={loading}
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
          </>
        }
      >
        <Confirmation
          title="Delete Category"
          description="Are you sure you want to delete this Category ?"
        />
      </CustomModal>
    </>
  );
};

export default Categories;
