import { useEffect, useRef } from "react";
import style from "./CustomModal.module.scss";
import close from '../../assets/images/cross.svg';

export interface CustomModalProps {
  title?: string;
  children: JSX.Element;
  footerNode?: JSX.Element;
  maxWidth?: string;
  modalVisible: boolean;
  transition?: boolean;
  spacingAround?: boolean;
  onClose?: () => void;
  scrollEnable?: boolean;
}

const CustomModal = ({
  title = "",
  children,
  footerNode,
  maxWidth = "700px",
  modalVisible = false,
  transition = true,
  spacingAround = true,
  onClose,
  scrollEnable = true,
}: CustomModalProps) => {
  const myElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (myElementRef.current && modalVisible) {
      myElementRef.current.scrollTo(0, 0);
    }
  }, [modalVisible]);

  useEffect(() => {
    const body = document.body;
    if (modalVisible) body.classList.add("isHidden");
    return () => {
      body.classList.remove("isHidden");
    };
  }, [modalVisible]);
  return (
    <div
      className={`${style.customModal} ${modalVisible && style.active} ${
        !transition && style.transitionDisable
      } ${!scrollEnable && style.isScroll}`}
    >
      <div className={style.customModal__backdrop} onClick={onClose}></div>
      <div
        className={style.customModal__content}
        style={{ maxWidth: maxWidth }}
      >
         <div
        className={style.customModal__close}
        onClick={onClose}
      >
        <img  src={close} alt="close" />
      </div>
        {title && (
          <header
            className={`${style.customModal__header} flex align-center justify-between`}
          >
            <h3>{title}</h3>
          </header>
        )}

        <div
          className={`${style.customModal__inner} ${
            !spacingAround && style.noSpacing
          } ${!scrollEnable && style.noScroll}`}
          ref={myElementRef}
        >
          {children}
        </div>

        {footerNode && (
          <footer className={`${style.customModal__footer} flex justify-center`}>
            {footerNode}
          </footer>
        )}
      </div>
    </div>
  );
};
export default CustomModal;
