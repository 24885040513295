import { useState } from "react";
import Logo from "../../components/Logo/Logo";
import styles from "./Login.module.scss";
import Input from "../../atoms/Input/Input";
import Button from "../../atoms/Button/Button";
import { FieldValues, useForm } from "react-hook-form";
import { logInSchema } from "./login.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { ApiService } from "../../services/apiServices";
import { StorageService } from "../../services/storage.service";
import { StorageEnums } from "../../enums/storageEnums";
import { toast } from "react-toastify";

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(logInSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods;

  const apiService = new ApiService();
  const storageService = new StorageService();

  const handleLogin = async (data: FieldValues) => {
    try {
      setLoading(true);
      let payload = {
        data,
        url: "admin/sign-in",
      };
      const res = await apiService.post(payload);
      storageService.setKey(StorageEnums.CREDENTIALS, res);
      navigate("/dashboard");
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={`${styles.login}`}>
      <div className={`flex-wrap ${styles.login__inner}`}>
        <div className={`${styles.login__logo}`}>
          <Logo />
        </div>
        <div className={`${styles.login__form}`}>
          <h2>Login</h2>
          <div className={`${styles.login__formGroup}`}>
            <form onSubmit={handleSubmit(handleLogin)} noValidate>
              <div className={`flex-column ${styles.login__formGroup}`}>
                <Input
                  type="text"
                  placeholder="Email*"
                  register={register}
                  registerName="email"
                  errorMessage={errors?.email?.message}
                />
                <Input
                  type="password"
                  placeholder="Password*"
                  register={register}
                  registerName="password"
                  errorMessage={errors?.password?.message}
                />
              </div>
              <Button
                label="Login"
                type="submit"
                buttonClass="full"
                loading={loading}
                disabled={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
