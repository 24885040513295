import { useState } from "react";
import Button from "../../atoms/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import { HOME_SIDEBAR_TABS } from "../../constants/sidebar.constants";
import { StorageService } from "../../services/storage.service";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import styles from "./Dashboard.module.scss";

const Dashboard = () => {
  const [showNav, setShowNav] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div className={`flex-wrap ${styles.dashboard}`}>
      <Sidebar
        SidebarTabData={HOME_SIDEBAR_TABS}
        showNav={showNav}
        setShowNav={setShowNav}
      />
      <div className={`${styles.dashboard__main}`}>
        <Header showNav={showNav} setShowNav={setShowNav} />
        <div className={`${styles.dashboard__content}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
