import React from "react";
import Table from "../Table/Table";

const MemberList = ({
  memberList,
  heading,
}: {
  heading: any;
  memberList: any;
}) => {
  return (
    <div>
      <Table headings={heading} tableData={memberList} />
    </div>
  );
};

export default MemberList;
