import { useFormContext } from "react-hook-form";
import Input from "../../atoms/Input/Input";

const FooterDescription = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <div className="flex-column gap-20">
      <Input
        type="textarea"
        placeholder="Description"
        label="Description *"
        register={register}
        registerName="footerDescription"
        errorMessage={errors?.footerDescription?.message}
      />
    </div>
  );
};

export default FooterDescription;
