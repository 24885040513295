import { useFormContext } from "react-hook-form";
import Input from "../../atoms/Input/Input";

const FooterCopyright = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="footerCopyrightTitle"
        errorMessage={errors?.footerCopyrightTitle?.message}
      />
    </div>
  );
};

export default FooterCopyright;
