import { ApiService } from "../services/apiServices";

const apiService = new ApiService();


export const getSignedUrl = async (data: {
    fileName: string;
    contentType: string;
  }) => {
    const payload = {
      data,
      url: "media/signed-url",
    };
    return await apiService.post(payload);
  };

  export  const uploadImage = async (image: File, uploadUrl: string) => {
    const obj = {
      data: image,
      PresignedUrl: true,
      url: uploadUrl,
      options:{ headers: { "Content-Type": image.type } }
    };
    return await apiService.put(obj);
  };
