import React, { ReactNode, useState } from "react";
import styles from "./Accordion.module.scss";
import plus from "../../assets/images/plus.svg";
import minus from "../../assets/images/minus.svg";

interface AccordionProps {
  title: string;
  children: ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={` ${styles.accordion}`}>
      <div role="button" className={`flex align-center justify-between ${styles.accordion__header}`} onClick={toggleAccordion}>
        <h2>{title}</h2>
        <span><img src={isOpen ? minus : plus} alt="icon" /></span>
      </div>
      {isOpen && <div className={` ${styles.accordion__content}`}>{children}</div>}
    </div>
  );
};

export default Accordion;
