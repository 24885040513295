import { ActionType, TableDataType, TableHeaderProps } from "../../interfaces/interface";

export const MEMBERS_HEADINGS: TableHeaderProps[] = [
  {
    name: "id",
    title: "Id",
    type: TableDataType.TEXT,
    onClick: (rowData: any) => {},
    customClass: "",
    customStyle: {},
  },
    {
      name: "name",
      title: "Name",
      type: TableDataType.TEXT,
      onClick: (rowData: any) => {},
      customClass: "",
      customStyle: {},
    },
    {
      name: "address",
      title: "Location",
      type: TableDataType.TEXT,
      onClick: (rowData: any) => {},
      customClass: "",
      customStyle: {},
    },
    
    {
      name: "phone",
      title: "Phone Number",
      type: TableDataType.PHONE_NUMBER,
      onClick: (rowData: any) => {},
      customClass: "",
      customStyle: {},
    },
    {
      name: "tier",
      title: "Status",
      type: TableDataType.TEXT,
      onClick: (rowData: any) => {},
      customClass: "",
      customStyle: {},
    },
    {
      name: "categories",
      title: "Category",
      type: TableDataType.TEXT,
      onClick: (rowData: any) => {},
      customClass: "",
      customStyle: {},
    },
    

    {
      name: "action",
      title: "Action",
      type: TableDataType.ACTIONS,
      actionType: [
        {
          icon: "",
          name: ActionType.EDIT,
          onClick: (rowData: any) => {},
          customClass: "",
          customStyle: {},
        },
        {
          icon: "",
          name: ActionType.DELETE,
          onClick: (rowData: any) => {},
          customClass: "",
          customStyle: {},
        },
        
      ],
      customClass: "",
      customStyle: {},
    },
  ];