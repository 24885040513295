import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";
import FileInput from "../../atoms/FileInput/FileInput";

const AboutPoweringSection = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="aboutUsPoweringTitle"
        errorMessage={errors?.aboutUsPoweringTitle?.message}
      />
      <Input
        type="text"
        placeholder="Subtitle"
        label="Subtitle*"
        register={register}
        registerName="aboutUsPoweringSubtitle"
        errorMessage={errors?.aboutUsPoweringSubtitle?.message}
      />
      <Input
        type="textarea"
        label="Description*"
        placeholder="Description"
        register={register}
        registerName="aboutUsPoweringDescription"
        errorMessage={errors?.aboutUsPoweringDescription?.message}
      />

      <FileInput
        label="Image*"
        register={register}
        registerName="aboutUsPoweringImage"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.aboutUsPoweringImage?.message}
        value={getValues("aboutUsPoweringImage") ?? ""}
      />

      <Input
        type="text"
        label="Title 1*"
        placeholder="Title 1"
        register={register}
        registerName="aboutUsPoweringTitle1"
        errorMessage={errors?.aboutUsPoweringTitle1?.message}
      />
      <Input
        type="text"
        label="Number 1*"
        placeholder="Number 1"
        register={register}
        registerName="aboutUsPoweringNumber1"
        errorMessage={errors?.aboutUsPoweringNumber1?.message}
      />
      <Input
        type="text"
        label="Title 2*"
        placeholder="Title 2"
        register={register}
        registerName="aboutUsPoweringTitle2"
        errorMessage={errors?.aboutUsPoweringTitle2?.message}
      />
      <Input
        type="text"
        label="Number 2*"
        placeholder="Number 2"
        register={register}
        registerName="aboutUsPoweringNumber2"
        errorMessage={errors?.aboutUsPoweringNumber2?.message}
      />
      <Input
        type="text"
        label="Title 1*"
        placeholder="Title 3"
        register={register}
        registerName="aboutUsPoweringTitle3"
        errorMessage={errors?.aboutUsPoweringTitle3?.message}
      />
      <Input
        type="text"
        label="Number 3*"
        placeholder="Number 3"
        register={register}
        registerName="aboutUsPoweringNumber3"
        errorMessage={errors?.aboutUsPoweringNumber3?.message}
      />
    </div>
  );
};

export default AboutPoweringSection;
