import { ActionType, TableDataType, TableHeaderProps } from "../../interfaces/interface";

export const CATEGORIES_HEADINGS: TableHeaderProps[] = [
  {
    name: "id",
    title: "ID",
    type: TableDataType.TEXT,
    onClick: (rowData: any) => {},
    customClass: "",
    customStyle: {},
  },
    {
      name: "name",
      title: "Name",
      type: TableDataType.TEXT,
      onClick: (rowData: any) => {},
      customClass: "",
      customStyle: {},
    },
    {
      name: "icon",
      title: "Icon",
      type: TableDataType.ICON,
      onClick: (rowData: any) => {},
      customClass: "",
      customStyle: {},
    },
    {
      name: "action",
      title: "Action",
      type: TableDataType.ACTIONS,
      actionType: [
        {
            icon: "",
            name: ActionType.EDIT,
            onClick: (rowData: any) => {},
            customClass: "",
            customStyle: {},
        },
        {
          icon: "",
          name: ActionType.DELETE,
          onClick: (rowData: any) => {},
          customClass: "",
          customStyle: {},
        },
      ],
      customClass: "",
      customStyle: {},
    },
  ];