import React, { useEffect, useRef, useState } from "react";
import { CustomInputProps } from "../../interfaces/interface";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./FileInput.module.scss";
import upload from "../../assets/images/upload.svg";
import close from "../../assets/images/cross.svg";
import { toast } from "react-toastify";
import { allowedTypes, maxSize } from "../../constants/constants";
import Skeleton from "../Skeleton/Skeleton";

const FileInput: React.FC<CustomInputProps> = ({
  label,
  value,
  errorMessage = "",
  register,
  registerName = "",
  disabled = false,
  accept = "image/*",
  control,
}: CustomInputProps) => {
  const { setValue, clearErrors, getValues, trigger } = useFormContext();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileList = e.dataTransfer.files;
    const file = fileList[0];

    if (fileList.length > 0) {
      if (!allowedTypes?.includes(file.type)) {
        toast.error("Please upload a PNG, JPG, JPEG, or SVG file");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setValue(registerName, null);
        return;
      }

      if (file.size > maxSize) {
        toast.error("File size exceeds 100MB limit");
        setValue(registerName, null);

        return;
      }
      setValue(registerName, fileList[0], { shouldDirty: true });
      trigger(registerName);
      displayImage(fileList[0]);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      if (!allowedTypes?.includes(file.type)) {
        toast.error("Please upload a PNG, JPG, JPEG, or SVG file");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setValue(registerName, null);
        return;
      }

      if (file.size > maxSize) {
        toast.error("File size exceeds 100MB limit");
        setValue(registerName, null);

        return;
      }

      setValue(registerName, file);
      displayImage(file);
    }
  };

  useEffect(() => {
    if (getValues(registerName)) clearErrors(registerName);
  }, [getValues(registerName)]);

  const displayImage = (file: File) => {
    setIsLoading(true);
    const reader = new FileReader();

    reader.onload = () => {
      setImageSrc(reader.result as string);
      setIsLoading(false);
    };

    reader.readAsDataURL(file);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setValue(registerName, null);
    trigger(registerName);
    setImageSrc(null);
  };

  return (
    <div className={`${styles.fileInput}`}>
      {label && <label>{label}</label>}
      {imageSrc ||
      value?.type?.startsWith("image/") ||
      value?.includes("https") ? (
        <div className={`${styles.fileInput__img}`}>
          <figure>
            <img
              onLoad={() => setIsLoading(false)}
              src={imageSrc ? imageSrc : value}
              alt="Selected file"
              style={{ display: "none" }}
            />
            {!isLoading && (
              <>
                <img
                  className={`${styles.fileInput__selected}`}
                  src={imageSrc ? imageSrc : value}
                  alt="Selected file"
                />
              </>
            )}
            <span role="button" onClick={handleRemove}>
              <img src={close} alt="remove" />{" "}
            </span>
            {isLoading && <Skeleton width="300px" height="300px" />}
          </figure>
        </div>
      ) : (
        <div
          onClick={handleClick}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
          onDrop={handleDrop}
          className={`flex align-center justify-center ${styles.fileInput__inner}`}
          role="button"
        >
          <div className={`${styles.fileInput__info}`}>
            <figure>
              <img src={upload} alt="upload" />
            </figure>
            <p>
              Click here to upload image or drag and drop your file here(Max
              file size is 100 mb of png, jpg , jpeg or svg type)
            </p>

            <Controller
              name={registerName}
              control={control}
              render={({ field }) => {
                return (
                  <input
                    type="file"
                    name={registerName}
                    disabled={disabled}
                    {...register(registerName)}
                    onChange={(e) => {
                      if (e?.target?.files?.[0]) handleFileChange(e);
                      if (e?.target?.files?.[0]?.size)
                        if (e?.target?.files?.[0]?.size < maxSize)
                          field.onChange(e.target.files?.[0]);
                    }}
                    accept={accept}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                );
              }}
            ></Controller>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className={`${styles.fileInput__error}`}>{errorMessage}</div>
      )}
    </div>
  );
};

export default FileInput;
