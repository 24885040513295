import * as yup from "yup";

export const contactUsSchema = yup.object().shape({
  contactUsBannerDescription: yup.string().required("Description is required").trim(),
  contactUsBannerTitle: yup.string().required("Title is required").trim(),
  contactUsBannerImage:yup.mixed().required("Image is required"),

  cardSectionMainDescription: yup.string().required("Description is required").trim(),
  cardSectionMainTitle: yup.string().required("Title is required").trim(),

  
  card1Title: yup.string().required("Title is required").trim(),
  card1Description: yup.string().required("Description is required").trim(),
  card1Icon: yup.mixed().required("Icon is required"),

  card2Title: yup.string().required("Title is required").trim(),
  card2Description: yup.string().required("Description is required").trim(),
  card2Icon: yup.mixed().required("Icon is required"),

  card3Title: yup.string().required("Title is required").trim(),
  card3Description: yup.string().required("Description is required").trim(),
  card3Icon: yup.mixed().required("Icon is required"),
  
  contactFormTitle: yup.string().required("Title is required").trim(),
  contactFormSubtitle: yup.string().required("Subtitle is required").trim(),
  });

