import { CategoryListType } from "../../interfaces/categories.interface";
import Table from "../Table/Table";

const CategoryList = ({
  tableData,
  categoryListHeaders,
}: CategoryListType) => {
  return (
    <>
      <Table headings={categoryListHeaders} tableData={tableData} />
    </>
  );
};

export default CategoryList;
