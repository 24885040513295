import * as yup from "yup";

export const urlRegExp = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+(\.[a-zA-Z]{2,}){1,3}(\/\S*)?$/;

export const footerSchema = yup.object().shape({
  footerDescription: yup.string().required("Description is required").trim(),
  footerTitle1: yup.string().required("Title 1 is required").trim(),
  footerTitle2: yup.string().required("Title 2 is required").trim(),
  footerTitle3: yup.string().required("Title 3 is required").trim(),
  footerTitle4: yup.string().required("Title 4 is required").trim(),
  footerSocialTitle: yup.string().required("Title is required").trim(),
  footerSocialLogo1: yup.mixed().required("Logo is required"),
  footerSocialURL1: yup.string().required("URL 1 is required").trim().matches(urlRegExp, 'Enter correct url'),
  footerSocialLogo2: yup.mixed().required("Logo is required"),
  footerSocialURL2: yup.string().required("URL 2 is required").trim().matches(urlRegExp, 'Enter correct url'),
  footerSocialLogo3: yup.mixed().required("Logo is required"),
  footerSocialURL3: yup.string().required("URL 3 is required").trim().matches(urlRegExp, 'Enter correct url'),
  footerCopyrightTitle: yup.string().required("Title 1 is required").trim(),
});
