import React, { useEffect } from "react";
import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";
import FileInput from "../../atoms/FileInput/FileInput";
import MultipleFileInput from "../../atoms/MutilpleFileInput/MultipleFileInput";

const HomeSliderSection = () => {
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        label="Title*"
        placeholder="Title"
        register={register}
        registerName="sliderTitle"
        errorMessage={errors?.sliderTitle?.message}
      />
      {/* <MultipleFileInput
        label="Upload Images"
        register={register} // Pass the register function from react-hook-form
        registerName="sliderImages" // Unique name for the file input field
        control={control} // Pass the control function from react-hook-form
        errorMessage={errors?.sliderImages?.message}
        accept="image/*" // Accept only image files
      /> */}
    </div>
  );
};

export default HomeSliderSection;
