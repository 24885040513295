import React from "react";
import LogoImage from "../../assets/images/logo.svg";
import styles from "./Logo.module.scss";

const Logo = ({IconOnly, logoBlack}:any) => {
  return <>
  <span   className={`flex align-center ${styles.logo} ${IconOnly && styles.logoIcon} ${logoBlack && styles.logoBlack}`}>
    <img src={LogoImage} alt="NATIONAL ELECTRICAL REGISTRY" title="NATIONAL ELECTRICAL REGISTRY" />
   {!IconOnly && <p>NATIONAL ELECTRICAL REGISTRY</p>} 
  </span>
  </>;
};

export default Logo;
