import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";
import FileInput from "../../atoms/FileInput/FileInput";

const AboutContractorsSection = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="aboutUsContractorsTitle"
        errorMessage={errors?.aboutUsContractorsTitle?.message}
      />
      <Input
        type="text"
        placeholder="Subtitle"
        label="Subtitle*"
        register={register}
        registerName="aboutUsContractorsSubtitle"
        errorMessage={errors?.aboutUsContractorsSubtitle?.message}
      />
      <Input
        type="textarea"
        label="Description*"
        placeholder="Description"
        register={register}
        registerName="aboutUsContractorsDescription"
        errorMessage={errors?.aboutUsContractorsDescription?.message}
      />

      <FileInput
        label="Image*"
        register={register}
        registerName="aboutUsContractorsImage"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.aboutUsContractorsImage?.message}
        value={getValues("aboutUsContractorsImage") ?? ""}
      />
    </div>
  );
};

export default AboutContractorsSection;
