import { ReactComponent as Home } from "../assets/images/home.svg"
import { ReactComponent as About } from "../assets/images/about.svg"
import { ReactComponent as Contact } from "../assets/images/contact.svg"
import { ReactComponent as Category } from "../assets/images/category.svg"
import { ReactComponent as Member } from "../assets/images/member.svg"
import { ReactComponent as Logout } from "../assets/images/logout.svg"
import { ReactComponent as Other } from "../assets/images/other.svg"
import { ReactComponent as Header } from "../assets/images/header.svg"
import { ReactComponent as Footer } from "../assets/images/footer.svg"
import { ReactComponent as LogoIcon } from "../assets/images/logo-icon.svg"
import { ReactComponent as Question } from "../assets/images/question-icon.svg"
import { SidebarTab } from "../interfaces/interface";


export const HOME_SIDEBAR_TABS: SidebarTab[] = [
  {
    name: "Home",
    icon:Home,
    path: "/dashboard/home",

  },
  {
    name: "About Us",
    icon:About,
    path: "/dashboard/about",
    
  },
  {
    name: "Contact Us",
    icon:Contact,
    path: "/dashboard/contact",
    
  },
  {
    name: "Categories",
    icon:Category,
    path: "/dashboard/categories",
    
  },
  {
    name: "Members",
    icon:Member,
    path: "/dashboard/members",
    
  },
  {
    name: "Contact Details",
    icon:Logout,
    path: "/dashboard/contact-details",
    
  },
  {
    name: "Others",
    icon:Other,
    path: "/dashboard/members",
    
  },
  {
    name: "LogOut",
    icon:Logout,
    path: "/login",
    
  },
 
];
export const HOME_SIDEBAR_OTHERS_TABS: SidebarTab[] = [
  {
    name: "Header",
    icon:Header,
    path: "/dashboard/header",
  },
  {
    name: "Footer",
    icon:Footer,
    path: "/dashboard/footer",
    
  },
  {
    name: "Logo",
    icon:LogoIcon,
    path: "/dashboard/logo",
    
  },
  {
    name: "Have a question?",
    icon:Question,
    path: "/dashboard/haq",
    
  },
  
 
];
