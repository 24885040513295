import { useFormContext } from "react-hook-form";
import Input from "../../atoms/Input/Input";
import FileInput from "../../atoms/FileInput/FileInput";

const FooterSocialLinks = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();
  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="footerSocialTitle"
        errorMessage={errors?.footerSocialTitle?.message}
      />
      <Input
        type="text"
        placeholder="URL 1"
        label="URL 1*"
        register={register}
        registerName="footerSocialURL1"
        errorMessage={errors?.footerSocialURL1?.message}
      />
      <FileInput
        label="Logo 1*"
        register={register}
        registerName="footerSocialLogo1"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.footerSocialLogo1?.message}
        value={getValues("footerSocialLogo1") ?? ""}
      />

      <Input
        type="text"
        placeholder="URL 2"
        label="URL 2*"
        register={register}
        registerName="footerSocialURL2"
        errorMessage={errors?.footerSocialURL2?.message}
      />
      <FileInput
        label="Logo 2*"
        register={register}
        registerName="footerSocialLogo2"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.footerSocialLogo2?.message}
        value={getValues("footerSocialLogo2") ?? ""}
      />

      <Input
        type="text"
        placeholder="URL 3"
        label="URL 3*"
        register={register}
        registerName="footerSocialURL3"
        errorMessage={errors?.footerSocialURL3?.message}
      />
      <FileInput
        label="Logo 3*"
        register={register}
        registerName="footerSocialLogo3"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.footerSocialLogo3?.message}
        value={getValues("footerSocialLogo3") ?? ""}
      />
    </div>
  );
};

export default FooterSocialLinks;
