import React from "react";
import FileInput from "../../atoms/FileInput/FileInput";
import { useFormContext } from "react-hook-form";
import Input from "../../atoms/Input/Input";

const HeaderNavigation = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();
  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title 1"
        label="Title 1*"
        register={register}
        registerName="headerBannerTitle1"
        errorMessage={errors?.headerBannerTitle1?.message}
      />
      <Input
        type="text"
        placeholder="Title 2"
        label="Title 2*"
        register={register}
        registerName="headerBannerTitle2"
        errorMessage={errors?.headerBannerTitle2?.message}
      />
      <Input
        type="text"
        placeholder="Title 3"
        label="Title 3*"
        register={register}
        registerName="headerBannerTitle3"
        errorMessage={errors?.headerBannerTitle3?.message}
      />

      <Input
        type="text"
        placeholder="Title 4"
        label="Title 4*"
        register={register}
        registerName="headerBannerTitle4"
        errorMessage={errors?.headerBannerTitle4?.message}
      />
      <Input
        type="text"
        placeholder="URL"
        label="URL*"
        register={register}
        registerName="headerBannerURL"
        errorMessage={errors?.headerBannerURL?.message}
      />
      <Input
        type="text"
        placeholder="Title 5"
        label="Title 5*"
        register={register}
        registerName="headerBannerTitle5"
        errorMessage={errors?.headerBannerTitle5?.message}
      />
    </div>
  );
};

export default HeaderNavigation;
