import axios from "axios";
import { StorageEnums } from "../enums/storageEnums";
import { ApiService } from "../services/apiServices";
import { StorageService } from "../services/storage.service";
const storageService = new StorageService();
const apiService = new ApiService();
const localStorageUser = JSON.parse(
  localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
);

const handleLogout=()=>{
  storageService.clearLocalStorage();
  window.location.href = window.location.origin;
}

const interceptor = () => {
  axios.interceptors.request.use(async (request) => {
    return request;
  });

  axios.interceptors.response.use(
    (res: any) => {
      return res;
    },
    async (error: any) => {
      if (error?.response?.status === 401) {
        let payload = {
          url: "admin/refresh",
          data: { refreshToken: localStorageUser?.data.refreshToken },
        };
        try{
          let resp = await apiService.post(payload);
          storageService.setKey(StorageEnums.CREDENTIALS, resp);
        }catch(error){
          handleLogout();  
        }
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
