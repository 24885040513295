import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import UnAuthGuard from "../guards/UnAuth.guard";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import AuthGuard from "../guards/Auth.guard";
import Home from "../pages/Home/Home";
import ContactUs from "../pages/ContactUs /ContactUs";
import About from "../pages/About/About";
import Members from "../pages/Members/Members";
import Header from "../pages/Header/Header";
import Footer from "../pages/Footer/Footer";
import Logo from "../pages/Logo/Logo";
import HaveAQuestion from "../pages/HaveAQuestion/HaveAQuestion";
import ContactDetails from "../pages/ContactDetails/ContactDetails";
import AddNewMember from "../components/AddNewMember/AddNewMember";
import Categories from "../pages/Categories/Categories";
import CreateCategory from "../components/CreateCategory/CreateCategory";

const RouterPath = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <UnAuthGuard>
          <Navigate to="login" />
        </UnAuthGuard>
      ),
    },
    {
      path: "/login",
      element: (
        <UnAuthGuard>
          <Login />
        </UnAuthGuard>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="home" replace={true} />,
        },
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },

        {
          path: "members",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Members />,
            },
            {
              path: "create",
              element: <AddNewMember />,
            },
            {
              path: "edit/:id",
              element: <AddNewMember />,
            },
          ],
        },
        {
          path: "contact",
          element: <ContactUs />,
        },
        {
          path: "categories",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Categories />,
            },
            {
              path: "create",
              element: <CreateCategory />,
            },
            {
              path: "edit/:id",
              element: <CreateCategory />,
            },
          ],
        },
        {
          path: "header",
          element: <Header />,
        },
        {
          path: "footer",
          element: <Footer />,
        },
        {
          path: "logo",
          element: <Logo />,
        },
        {
          path: "haq",
          element: <HaveAQuestion />,
        },
        {
          path: "contact-details",
          element: <ContactDetails />,
        },
      ],
    },

    {
      path: "*",
      element: (
        // <UnAuthGuard>
        <NotFoundPage />
        // </UnAuthGuard>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};
export default RouterPath;
