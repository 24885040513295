import * as yup from "yup";
import { urlRegExp } from "../Footer/footer.schema";

export const headerSchema = yup.object().shape({

  headerBannerTitle: yup.string().required("Title is required").trim(),
  logo:yup.mixed().required("Logo is required"),
  headerBannerTitle1: yup.string().required("Title 1 is required").trim(),
  headerBannerTitle2: yup.string().required("Title 2 is required").trim(),
  headerBannerTitle3: yup.string().required("Title 3 is required").trim(),
  headerBannerTitle4: yup.string().required("Title 4 is required").trim(),
  headerBannerTitle5: yup.string().required("Title 5 is required").trim(),
  headerBannerURL: yup.string().required("URL is required").trim().matches(urlRegExp, 'Enter correct url')
  
  
  });

