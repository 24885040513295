import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";
import FileInput from "../../atoms/FileInput/FileInput";

const AboutHomeOwnersSection = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="aboutUsHomeOwnersTitle"
        errorMessage={errors?.aboutUsHomeOwnersTitle?.message}
      />
      <Input
        type="text"
        placeholder="Subtitle"
        label="Subtitle*"
        register={register}
        registerName="aboutUsHomeOwnersSubtitle"
        errorMessage={errors?.aboutUsHomeOwnersSubtitle?.message}
      />
      <Input
        type="textarea"
        label="Description*"
        placeholder="Description"
        register={register}
        registerName="aboutUsHomeOwnersDescription"
        errorMessage={errors?.aboutUsHomeOwnersDescription?.message}
      />

      <FileInput
        label="Image*"
        register={register}
        registerName="aboutUsHomeOwnersImage"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.aboutUsHomeOwnersImage?.message}
        value={getValues("aboutUsHomeOwnersImage") ?? ""}
      />
    </div>
  );
};

export default AboutHomeOwnersSection;
