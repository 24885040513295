import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../atoms/Button/Button";
import { aboutSchema } from "./about.schema";
import styles from "./About.module.scss";
import Accordion from "../../components/Accordian/Accordian";
import { ApiService } from "../../services/apiServices";
import {
  AboutUsHeading,
  AboutUsSections,
  Page,
  StorageEnums,
} from "../../enums/storageEnums";
import { toast } from "react-toastify";
import GlobalLoader from "../../atoms/GlobalLodaer/GlobalLoader";
import { getSignedUrl, uploadImage } from "../../constants/commonFunctions";
import AboutBannerSection from "../../components/AboutBannerSection/AboutBannerSection";
import AboutPoweringSection from "../../components/AboutPoweringSection/AboutPoweringSection";
import AboutHomeOwnersSection from "../../components/AboutHomeOwnersSection/AboutHomeOwnersSection";
import AboutContractorsSection from "../../components/AboutContractorsSection/AboutContractorsSection";
import AboutFounderSection from "../../components/AboutFounderSection/AboutFounderSection";

export interface APIResponse {
  id: string;
  section: string;
  key: string;
  content: string;
  page: string;
  order: number;
  isPublish: boolean;
}

const ContactUs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForPublish, setLoadingForPublish] = useState<boolean>(false);
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
  const [aboutUsPageData, setAboutUsPageData] = useState<APIResponse[]>([]);
  const [isPublish, setIsPublish] = useState<boolean>(true);
  const apiService = new ApiService();
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const methods = useForm({
    resolver: yupResolver(aboutSchema),
  });
  const { handleSubmit, formState } = methods;

  const CloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    // Listen for changes in any form field
    setIsFormEdited(formState.isDirty);
  }, [formState]);

  const getAboutUsPageData = async () => {
    const payload = {
      url: `pages/${Page.ABOUT_US}`,
      headerToken: localStorageUser.data.accessToken,
    };
    const response = await apiService.get(payload);
    const data = response?.data?.result;
    setAboutUsPageData(data);

    if (data.length) {
      setIsPublish(data[0].isPublish);
      const resetObject: { [key: string]: any } = {};
      data.forEach((item: any) => {
        resetObject[item.key] = item.content;
      });
      methods.reset(resetObject);
    } else {
      setIsPublish(true);
    }
  };

  useEffect(() => {
    getAboutUsPageData();
  }, []);

  const handleSave = async (data: FieldValues) => {
    const {
      aboutUsBannerImage,
      aboutUsPoweringImage,
      aboutUsHomeOwnersImage,
      aboutUsContractorsImage,
      aboutFounderImage,
    } = data;

    const imagesToUpload = [
      { type: "aboutUsBannerImage", image: aboutUsBannerImage },
      { type: "aboutUsPoweringImage", image: aboutUsPoweringImage },
      { type: "aboutUsHomeOwnersImage", image: aboutUsHomeOwnersImage },
      { type: "aboutUsContractorsImage", image: aboutUsContractorsImage },
      { type: "aboutFounderImage", image: aboutFounderImage },
    ];

    if (!aboutUsPageData.length) {
      try {
        setLoading(true);

        // Generate signed URLs for each image
        const signedUrls = await Promise.all(
          imagesToUpload.map(async (imageObject) => {
            const { image } = imageObject;
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // Banner section
          {
            section: AboutUsSections.ABOUT_US_BANNER_SECTION,
            key: AboutUsHeading.ABOUT_US_BANNER_TITLE,
            content: data[AboutUsHeading.ABOUT_US_BANNER_TITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_BANNER_SECTION,
            key: AboutUsHeading.ABOUT_US_BANNER_DESCRIPTIION,
            content: data[AboutUsHeading.ABOUT_US_BANNER_DESCRIPTIION],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_BANNER_SECTION,
            key: AboutUsHeading.ABOUT_US_BANNER_IMAGE,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_BANNER_IMAGE
                )?.key || "",
            page: Page.ABOUT_US,
          },

          // POWERING SECTION
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_SUBTITLE,
            content: data[AboutUsHeading.ABOUT_US_POWERING_SUBTITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_DESCRIPTIION,
            content: data[AboutUsHeading.ABOUT_US_POWERING_DESCRIPTIION],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_IMAGE,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_POWERING_IMAGE
                )?.key || "",
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE1,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE1],
            page: Page.ABOUT_US,
          },

          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_NUMBER1,
            content: data[AboutUsHeading.ABOUT_US_POWERING_NUMBER1],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE2,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE2],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_NUMBER2,
            content: data[AboutUsHeading.ABOUT_US_POWERING_NUMBER2],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE3,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE3],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_NUMBER3,
            content: data[AboutUsHeading.ABOUT_US_POWERING_NUMBER3],
            page: Page.ABOUT_US,
          },

          // HOMEOWNERS SECTION
          {
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_TITLE,
            content: data[AboutUsHeading.ABOUT_US_HOMEOWNERS_TITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_SUBTITLE,
            content: data[AboutUsHeading.ABOUT_US_HOMEOWNERS_SUBTITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_DESCRIPTION,
            content: data[AboutUsHeading.ABOUT_US_HOMEOWNERS_DESCRIPTION],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_IMAGE,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_HOMEOWNERS_IMAGE
                )?.key || "",
            page: Page.ABOUT_US,
          },
          // CONTRACTOR SECTION

          {
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_TITLE,
            content: data[AboutUsHeading.ABOUT_US_CONTRACTORS_TITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_SUBTITLE,
            content: data[AboutUsHeading.ABOUT_US_CONTRACTORS_SUBTITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_DESCRIPTION,
            content: data[AboutUsHeading.ABOUT_US_CONTRACTORS_DESCRIPTION],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) =>
                    url.type === AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE
                )?.key || "",
            page: Page.ABOUT_US,
          },

          //FOUNDER SECTION
          {
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_NAME,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_NAME],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_DESIGNATION,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_DESIGNATION],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_TITLE,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_TITLE],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_DESCRIPTION,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_DESCRIPTION],
            page: Page.ABOUT_US,
          },
          {
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_IMAGE,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_FOUNDER_IMAGE
                )?.key || "",
            page: Page.ABOUT_US,
          },
        ];

        // Upload images
        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );

        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.ABOUT_US}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        methods.reset();
        getAboutUsPageData();

        toast.success("Data uploaded successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    } else {
      const nonStringImages = imagesToUpload.filter(
        (image) => typeof image.image !== "string"
      );

      try {
        setLoading(true);
        const signedUrls = await Promise.all(
          nonStringImages.map(async (imageObject) => {
            const { image } = imageObject;
            const imageKey = data[image];
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // Banner section

          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_BANNER_TITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_BANNER_SECTION,
            key: AboutUsHeading.ABOUT_US_BANNER_TITLE,
            content: data[AboutUsHeading.ABOUT_US_BANNER_TITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_BANNER_DESCRIPTIION
            )?.id,
            section: AboutUsSections.ABOUT_US_BANNER_SECTION,
            key: AboutUsHeading.ABOUT_US_BANNER_DESCRIPTIION,
            content: data[AboutUsHeading.ABOUT_US_BANNER_DESCRIPTIION],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_BANNER_IMAGE
            )?.id,
            section: AboutUsSections.ABOUT_US_BANNER_SECTION,
            key: AboutUsHeading.ABOUT_US_BANNER_IMAGE,
            content: signedUrls?.find(
              (url) => url.type === AboutUsHeading.ABOUT_US_BANNER_IMAGE
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_BANNER_IMAGE
                )?.key
              : aboutUsPageData.find(
                  (item) => item.key === AboutUsHeading.ABOUT_US_BANNER_IMAGE
                )?.content || "",
            page: Page.ABOUT_US,
          },

          // POWERING SECTION

          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_TITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_SUBTITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_SUBTITLE,
            content: data[AboutUsHeading.ABOUT_US_POWERING_SUBTITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) =>
                item.key === AboutUsHeading.ABOUT_US_POWERING_DESCRIPTIION
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_DESCRIPTIION,
            content: data[AboutUsHeading.ABOUT_US_POWERING_DESCRIPTIION],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_IMAGE
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_IMAGE,
            content: signedUrls?.find(
              (url) => url.type === AboutUsHeading.ABOUT_US_POWERING_IMAGE
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_POWERING_IMAGE
                )?.key
              : aboutUsPageData.find(
                  (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_IMAGE
                )?.content || "",
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_TITLE1
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE1,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE1],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_NUMBER1
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_NUMBER1,
            content: data[AboutUsHeading.ABOUT_US_POWERING_NUMBER1],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_TITLE2
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE2,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE2],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_NUMBER2
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_NUMBER2,
            content: data[AboutUsHeading.ABOUT_US_POWERING_NUMBER2],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_TITLE3
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_TITLE3,
            content: data[AboutUsHeading.ABOUT_US_POWERING_TITLE3],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_POWERING_NUMBER3
            )?.id,
            section: AboutUsSections.ABOUT_US_POWERING_SECTION,
            key: AboutUsHeading.ABOUT_US_POWERING_NUMBER3,
            content: data[AboutUsHeading.ABOUT_US_POWERING_NUMBER3],
            page: Page.ABOUT_US,
          },

          // HOMEOWNER SECTION
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_HOMEOWNERS_TITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_TITLE,
            content: data[AboutUsHeading.ABOUT_US_HOMEOWNERS_TITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_HOMEOWNERS_SUBTITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_SUBTITLE,
            content: data[AboutUsHeading.ABOUT_US_HOMEOWNERS_SUBTITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) =>
                item.key === AboutUsHeading.ABOUT_US_HOMEOWNERS_DESCRIPTION
            )?.id,
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_DESCRIPTION,
            content: data[AboutUsHeading.ABOUT_US_HOMEOWNERS_DESCRIPTION],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_HOMEOWNERS_IMAGE
            )?.id,
            section: AboutUsSections.ABOUT_US_HOMEOWNERS_SECTION,
            key: AboutUsHeading.ABOUT_US_HOMEOWNERS_IMAGE,
            content: signedUrls?.find(
              (url) => url.type === AboutUsHeading.ABOUT_US_HOMEOWNERS_IMAGE
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_HOMEOWNERS_IMAGE
                )?.key
              : aboutUsPageData.find(
                  (item) =>
                    item.key === AboutUsHeading.ABOUT_US_HOMEOWNERS_IMAGE
                )?.content || "",
            page: Page.ABOUT_US,
          },

          // CONTRACTOR SECTION
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_CONTRACTORS_TITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_TITLE,
            content: data[AboutUsHeading.ABOUT_US_CONTRACTORS_TITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) =>
                item.key === AboutUsHeading.ABOUT_US_CONTRACTORS_SUBTITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_SUBTITLE,
            content: data[AboutUsHeading.ABOUT_US_CONTRACTORS_SUBTITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) =>
                item.key === AboutUsHeading.ABOUT_US_CONTRACTORS_DESCRIPTION
            )?.id,
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_DESCRIPTION,
            content: data[AboutUsHeading.ABOUT_US_CONTRACTORS_DESCRIPTION],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE
            )?.id,
            section: AboutUsSections.ABOUT_US_CONTRACTORS_SECTION,
            key: AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE,
            content: signedUrls?.find(
              (url) => url.type === AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) =>
                    url.type === AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE
                )?.key
              : aboutUsPageData.find(
                  (item) =>
                    item.key === AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE
                )?.content || "",
            page: Page.ABOUT_US,
          },
          //FOUNDER SECTION
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_FOUNDER_NAME
            )?.id,
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_NAME,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_NAME],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_FOUNDER_DESIGNATION
            )?.id,
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_DESIGNATION,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_DESIGNATION],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_FOUNDER_TITLE
            )?.id,
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_TITLE,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_TITLE],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_FOUNDER_DESCRIPTION
            )?.id,
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_DESCRIPTION,
            content: data[AboutUsHeading.ABOUT_US_FOUNDER_DESCRIPTION],
            page: Page.ABOUT_US,
          },
          {
            id: aboutUsPageData.find(
              (item) => item.key === AboutUsHeading.ABOUT_US_FOUNDER_IMAGE
            )?.id,
            section: AboutUsSections.ABOUT_US_FOUNDER_SECTION,
            key: AboutUsHeading.ABOUT_US_FOUNDER_IMAGE,
            content: signedUrls?.find(
              (url) => url.type === AboutUsHeading.ABOUT_US_FOUNDER_IMAGE
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === AboutUsHeading.ABOUT_US_FOUNDER_IMAGE
                )?.key
              : aboutUsPageData.find(
                  (item) =>
                    item.key === AboutUsHeading.ABOUT_US_CONTRACTORS_IMAGE
                )?.content || "",
            page: Page.ABOUT_US,
          },
        ];

        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );
        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.ABOUT_US}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        getAboutUsPageData();
        toast.success("Data updated successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePublish = async () => {
    setLoadingForPublish(true);
    try {
      const payload = {
        url: `publish/pages/${Page.ABOUT_US}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.post(payload);
      toast.success("Published successfully");
      getAboutUsPageData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoadingForPublish(false);
    }
  };

  return (
    <div className={`${styles.home}`}>
      <div
        className={`flex align-center justify-between ${styles.home__header}`}
      >
        <h2>About Us</h2>
        <div className={`flex align-center ${styles.home__button}`}>
          <Button
            label="Save"
            type="submit"
            buttonClass="md"
            loading={loading}
            disabled={loading || !isFormEdited}
            onClick={handleSubmit(handleSave)}
          />

          <Button
            label="Publish"
            type="submit"
            buttonClass="md"
            loading={loadingForPublish}
            disabled={isPublish}
            onClick={handlePublish}
          />
        </div>
      </div>

      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          event.preventDefault()
        }
        noValidate
      >
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="About Us Banner">
              <AboutBannerSection />
            </Accordion>
          </FormProvider>
          <FormProvider {...methods}>
            <Accordion title="Powering Tomorrow">
              <AboutPoweringSection />
            </Accordion>
          </FormProvider>
          <FormProvider {...methods}>
            <Accordion title="Homeowners">
              <AboutHomeOwnersSection />
            </Accordion>
          </FormProvider>
          <FormProvider {...methods}>
            <Accordion title="Contractors">
              <AboutContractorsSection />
            </Accordion>
          </FormProvider>
          <FormProvider {...methods}>
            <Accordion title="Founder">
              <AboutFounderSection />
            </Accordion>
          </FormProvider>
        </div>
      </form>
      {loading && <GlobalLoader />}
    </div>
  );
};

export default ContactUs;
