import axios from "axios";
import { ApiDataObject } from "../interfaces/interface";

const baseurl = process.env.REACT_APP_BASE_URL;
export class ApiService {
  async get(obj: ApiDataObject) {
    return await axios.get(`${baseurl}${obj.url}`,{
      headers: {
        Authorization: obj.headerToken,
      },
    });
  }
  async post(obj: ApiDataObject) {
    return await axios.post(`${baseurl}${obj.url}`, obj?.data, {
      headers: {
        Authorization: obj.headerToken,
      },
    });
  }

  async delete(obj: ApiDataObject) {
    return await axios.delete(`${baseurl}${obj.url}`, {
      headers: {
        Authorization: obj.headerToken,
      },
    });
  }
  async put(obj: ApiDataObject) {
    if (obj.PresignedUrl) {
      return await axios.put(obj.url, obj?.data,obj.options);
    } else {
    return await axios.put(`${baseurl}${obj.url}`, obj?.data,{
      headers: {
        Authorization: obj.headerToken,
      },
    } );
    }
  }
  async patch(obj: ApiDataObject) {
    return await axios.patch(`${baseurl}${obj.url}`, obj?.data, {});
  }
}
