export enum StorageEnums {
  CREDENTIALS = "CREDENTIALS_NER",

}

export enum Sections {
  HOME_BANNER_SECTION_1 = "homeSection1",
  HOME_BANNER_SECTION_2 = "homeSection2",
  HOME_BANNER_SECTION_3 = "homeSection3",
}


export enum HeaderSections {
  HEADER_SECTION_1 = "logo",
  HEADER_SECTION_2 = "navigation",

}

export enum FooterSections {
 FOOTER_DESCRIPTION_SECTION = "footerDescription",
 FOOTER_NAVIGATION_SECTION = "footerNavigation",
 FOOTER_SOCIAL_SECTION = "footerSocial",
 FOOTER_COPYRIGHT_SECTION = "footerCopyright",

}

export enum ContactUsSections {
  CONTACT_US_BANNER_SECTION = "contactUsBannerSection",
  CONTACT_US_CARD_SECTION = "contactUsCardSection",
  CONTACT_US_FORM_SECTION = "contactUsFormSection",

 }

 export enum AboutUsSections {
  ABOUT_US_BANNER_SECTION = "aboutUsBannerSection",
  ABOUT_US_POWERING_SECTION = "abouttUsPoweringSection",
  ABOUT_US_HOMEOWNERS_SECTION = "abouttUsHomeownersSection",
  ABOUT_US_CONTRACTORS_SECTION = "abouttUsContractorsSection",
  ABOUT_US_FOUNDER_SECTION = "abouttUsFounderSection",
 }

 export enum LogoSections {
  LOGO_SECTION="logoSection",
 }

 export enum HAQSections {
  HAQ_SECTION="haqSection",
 }




export enum Page {
  HOME_PAGE = "home",
  HEADER = "header",
  FOOTER = "footer",
  CONTACT_US="contact",
  ABOUT_US="about",
  LOGO="logo",
  HAQ="haq",
  CONTACT_DETAILS="contact-us"
}

export enum Headings {
  HOME_BANNER_TITLE = "homeBannerTitle",
  HOME_BANNER_DESCRIPTIION = "homeBannerDescription",
  HOME_BANNER_IMAGE = "homeBannerImage",

  HOME_SECTION2_MAIN_TITLE = "cardSectionMainTitle",
  HOME_SECTION2_MAIN_DESCRIPTION = "cardSectionMainDescription",

  HOME_SECTION2_CARD1_TITLE = "card1Title",
  HOME_SECTION2_CARD1_DESCRIPTION = "card1Description",
  HOME_SECTION2_CARD1_ICON = "card1Icon",
  
  HOME_SECTION2_CARD2_TITLE = "card2Title",
  HOME_SECTION2_CARD2_DESCRIPTION = "card2Description",
  HOME_SECTION2_CARD2_ICON = "card2Icon",

  HOME_SECTION2_CARD3_TITLE = "card3Title",
  HOME_SECTION2_CARD3_DESCRIPTION = "card3Description",
  HOME_SECTION2_CARD3_ICON = "card3Icon",

  HOME_SECTION3_TITLE = "sliderTitle",

}

export enum HeaderHeading {
  HEADER_LOGO = "logo",
  HEADER_BANNER_TITLE = "headerBannerTitle",
  HEADER_BANNER_TITLE1 = "headerBannerTitle1",
  HEADER_BANNER_TITLE2 = "headerBannerTitle2",
  HEADER_BANNER_TITLE3 = "headerBannerTitle3",
  HEADER_BANNER_TITLE4 = "headerBannerTitle4",
  HEADER_BANNER_TITLE5 = "headerBannerTitle5",
  HEADER_BANNER_URL="headerBannerURL"

}

export enum FooterHeading {
  FOOTER_DESCRIPTION = "footerDescription",

  FOOTER_TITLE1 = "footerTitle1",
  FOOTER_TITLE2 = "footerTitle2",
  FOOTER_TITLE3 = "footerTitle3",
  FOOTER_TITLE4 = "footerTitle4",

  FOOTER_SOCIAL_TITLE = "footerSocialTitle",
  FOOTER_SOCIAL_LOGO1 ="footerSocialLogo1",
  FOOTER_SOCIAL_URL1 ="footerSocialURL1",
  FOOTER_SOCIAL_LOGO2 ="footerSocialLogo2",
  FOOTER_SOCIAL_URL2 ="footerSocialURL2",
  FOOTER_SOCIAL_LOGO3 ="footerSocialLogo3",
  FOOTER_SOCIAL_URL3 ="footerSocialURL3",

  FOOTER_COPYRIGHT_TITLE ="footerCopyrightTitle",

}

export enum ContactUsHeading {
  CONTACT_US_BANNER_TITLE = "contactUsBannerTitle",
  CONTACT_US_BANNER_DESCRIPTIION = "contactUsBannerDescription",
  CONTACT_US_BANNER_IMAGE = "contactUsBannerImage",

  CONTACT_US_CARD_SECTION_MAIN_TITLE = "cardSectionMainTitle",
  CONTACT_US_CARD_SECTION_MAIN_DESCRIPTION = "cardSectionMainDescription",

  CONTACT_US_CARD1_TITLE = "card1Title",
  CONTACT_US_CARD1_DESCRIPTION = "card1Description",
  CONTACT_US_CARD1_ICON = "card1Icon",
  
  CONTACT_US_CARD2_TITLE = "card2Title",
  CONTACT_US_CARD2_DESCRIPTION = "card2Description",
  CONTACT_US_CARD2_ICON = "card2Icon",

  CONTACT_US_CARD3_TITLE = "card3Title",
  CONTACT_US_CARD3_DESCRIPTION = "card3Description",
  CONTACT_US_CARD3_ICON = "card3Icon",

  CONTACT_US_FORM_TITLE = "contactFormTitle",
  CONTACT_US_FORM_SUBTITLE = "contactFormSubtitle",

}

export enum AboutUsHeading {
  ABOUT_US_BANNER_TITLE = "aboutUsBannerTitle",
  ABOUT_US_BANNER_DESCRIPTIION = "aboutUsBannerDescription",
  ABOUT_US_BANNER_IMAGE = "aboutUsBannerImage",

  ABOUT_US_POWERING_TITLE = "aboutUsPoweringTitle",
  ABOUT_US_POWERING_SUBTITLE = "aboutUsPoweringSubtitle",
  ABOUT_US_POWERING_DESCRIPTIION = "aboutUsPoweringDescription",
  ABOUT_US_POWERING_IMAGE = "aboutUsPoweringImage",
  ABOUT_US_POWERING_TITLE1 = "aboutUsPoweringTitle1",
  ABOUT_US_POWERING_NUMBER1 = "aboutUsPoweringNumber1",
  ABOUT_US_POWERING_TITLE2 = "aboutUsPoweringTitle2",
  ABOUT_US_POWERING_NUMBER2 = "aboutUsPoweringNumber2",
  ABOUT_US_POWERING_TITLE3 = "aboutUsPoweringTitle3",
  ABOUT_US_POWERING_NUMBER3 = "aboutUsPoweringNumber3",

  ABOUT_US_HOMEOWNERS_TITLE = "aboutUsHomeOwnersTitle",
  ABOUT_US_HOMEOWNERS_SUBTITLE = "aboutUsHomeOwnersSubtitle",
  ABOUT_US_HOMEOWNERS_DESCRIPTION = "aboutUsHomeOwnersDescription",
  ABOUT_US_HOMEOWNERS_IMAGE = "aboutUsHomeOwnersImage",

  ABOUT_US_CONTRACTORS_TITLE = "aboutUsContractorsTitle",
  ABOUT_US_CONTRACTORS_SUBTITLE = "aboutUsContractorsSubtitle",
  ABOUT_US_CONTRACTORS_DESCRIPTION = "aboutUsContractorsDescription",
  ABOUT_US_CONTRACTORS_IMAGE = "aboutUsContractorsImage",

  ABOUT_US_FOUNDER_NAME = "aboutFounderName",
  ABOUT_US_FOUNDER_DESIGNATION = "aboutFounderDesignation",
  ABOUT_US_FOUNDER_IMAGE = "aboutFounderImage",
  ABOUT_US_FOUNDER_TITLE = "aboutFounderTitle",
  ABOUT_US_FOUNDER_DESCRIPTION = "aboutFounderDescription",

}

export enum LogoHeading {
  LOGO_IMAGE="logoImage",
  LOGO_TITILE="logoTitle"

}
export enum HAQHeading {
  HAQ_TITILE="HAQTitle",
  HAQ_DESCRIPTION="HAQDescription",
  HAQ_BUTTON="HAQButton",
}