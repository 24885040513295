import React, { useEffect, useMemo, useState } from "react";
import { StorageEnums } from "../../enums/storageEnums";
import { ApiService } from "../../services/apiServices";
import TableLoader from "../../components/Table/TableLoader";
import { CONTACT_DETAILS_HEADINGS } from "./contactDetails.constants";
import Table from "../../components/Table/Table";
import { ActionType, TableDataType } from "../../interfaces/interface";
import CustomModal from "../../components/CustomModal/CustomModal";
import Button from "../../atoms/Button/Button";
import Confirmation from "../../components/Confirmation/Confirmation";
import { toast } from "react-toastify";
import Pagination from "../../components/Pagination/Pagination";
import styles from "./ContactDetails.module.scss";

interface APIResponse {
  createdAt: string;
  email: string;
  id: string;
  name: string;
  message: string;
  phone: string;
}
export interface PagingParameters {
  pageNumber: number;
  recordsPerPage: number;
}
const ContactDetails = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForDelete, setLoadingForDelete] = useState<boolean>(false);
  const [contactDetails, setContactDetails] = useState<APIResponse[]>([]);
  const apiService = new ApiService();
  const [pagingParameters, setPagingParameters] = useState<PagingParameters>({
    pageNumber: 1,
    recordsPerPage: 10,
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>("");

  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery.trim());
    }, 500); // Adjust the delay time as needed
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);
  async function getContactsDetails(page: any) {
    try {
      setLoading(true);
      const payload = {
        url: `pages/contact-us?page=${page}&limit=10&search=${searchQuery.trim()}`,
        headerToken: localStorageUser.data.accessToken,
      };
      let response = await apiService.get(payload);

      setTotalCount(response.data.count);
      setContactDetails(response.data.result);
    } catch (error) {
      console.error("Error fetching franchise data:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getContactsDetails(pagingParameters.pageNumber);
  }, [pagingParameters.pageNumber, debouncedSearchQuery]);

  const handleDelete = async (data: APIResponse) => {
    setConfirmationModalVisible(true);
    setDeleteId(data.id);
  };
  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };
  const handleConfirmDelete = async () => {
    setConfirmationModalVisible(false);
    setLoadingForDelete(true);
    try {
      const payload = {
        url: `pages/contact-us/${deleteId}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.delete(payload);
      toast.success("Deleted successfully");
      handlePageChange(1);
      getContactsDetails(1);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingForDelete(false);
    }
  };
  const contactDetailsHeaders = useMemo(() => {
    return CONTACT_DETAILS_HEADINGS.map((el) => {
      if (el.type === TableDataType.ACTIONS) {
        el?.actionType?.forEach((e) => {
          if (e.name === ActionType.DELETE) {
            e.onClick = handleDelete;
          }
        });
      }
      return el;
    });
  }, []);

  function handlePageChange(page: number) {
    setPagingParameters((prevParameters) => ({
      ...prevParameters,
      pageNumber: page,
    }));
  }

  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value);
    // Reset page number to 1 when initiating a new search
    handlePageChange(1);
  }
  return (
    <div className={`${styles.contact}`}>
      <div className={`${styles.contact__search}`}>
        <input
          type="text"
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
      </div>
      {loading ? (
        <>
          <TableLoader
            rows={8}
            headings={contactDetailsHeaders}
            columns={contactDetailsHeaders?.length}
          />
        </>
      ) : (
        <Table headings={contactDetailsHeaders} tableData={contactDetails} />
      )}
      <Pagination
        page={pagingParameters?.pageNumber}
        totalPages={Math.ceil(totalCount / pagingParameters.recordsPerPage)}
        onPageChange={handlePageChange}
        total={totalCount}
        limit={pagingParameters.recordsPerPage}
      />

      <CustomModal
        maxWidth="480px"
        title=""
        modalVisible={isConfirmationModalVisible}
        onClose={handleCloseConfirmationModal}
        footerNode={
          <>
            <Button
              buttonClass="md outline"
              onClick={handleCloseConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              buttonClass="md"
              loading={loadingForDelete}
              disabled={loadingForDelete}
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
          </>
        }
      >
        <Confirmation
          title="Delete Message"
          description="Are you sure you want to delete this message ?"
        />
      </CustomModal>
    </div>
  );
};

export default ContactDetails;
