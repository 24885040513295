import * as yup from "yup";

export const aboutSchema = yup.object().shape({
  aboutUsBannerDescription: yup.string().required("Description is required").trim(),
  aboutUsBannerTitle: yup.string().required("Title is required").trim(),
  aboutUsBannerImage:yup.mixed().required("Image is required"),


  aboutUsPoweringTitle: yup.string().required("Title is required").trim(),
  aboutUsPoweringSubtitle: yup.string().required("Subtitle is required").trim(),
  aboutUsPoweringDescription: yup.string().required("Description is required").trim(),
  aboutUsPoweringImage: yup.mixed().required("Image is required"),
  aboutUsPoweringTitle1: yup.string().required("Title is required").trim(),
  aboutUsPoweringNumber1: yup.string().required("Number is required").trim(),
  aboutUsPoweringTitle2: yup.string().required("Title is required").trim(),
  aboutUsPoweringNumber2: yup.string().required("Number is required").trim(),
  aboutUsPoweringTitle3: yup.string().required("Title is required").trim(),
  aboutUsPoweringNumber3: yup.string().required("Number is required").trim(),

  aboutUsHomeOwnersTitle: yup.string().required("Title is required").trim(),
  aboutUsHomeOwnersSubtitle: yup.string().required("Subtitle is required").trim(),
  aboutUsHomeOwnersDescription: yup.string().required("Description is required").trim(),
  aboutUsHomeOwnersImage: yup.mixed().required("Image is required"),

  aboutUsContractorsTitle: yup.string().required("Title is required").trim(),
  aboutUsContractorsSubtitle: yup.string().required("Subtitle is required").trim(),
  aboutUsContractorsDescription: yup.string().required("Description is required").trim(),
  aboutUsContractorsImage: yup.mixed().required("Image is required"),

 
  aboutFounderName: yup.string().required("Name is required").trim(),
  aboutFounderDesignation: yup.string().required("Designation is required").trim(),
  aboutFounderImage: yup.mixed().required("Image is required"),
  aboutFounderTitle: yup.string().required("Title is required").trim(),
  aboutFounderDescription: yup.string().required("Description is required").trim(),


  
  
  });


  