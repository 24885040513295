import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../atoms/Button/Button";
import styles from "./Logo.module.scss";
import Accordion from "../../components/Accordian/Accordian";
import { ApiService } from "../../services/apiServices";
import {
  LogoHeading,
  LogoSections,
  Page,
  StorageEnums,
} from "../../enums/storageEnums";
import { toast } from "react-toastify";
import GlobalLoader from "../../atoms/GlobalLodaer/GlobalLoader";
import { getSignedUrl, uploadImage } from "../../constants/commonFunctions";
import { logoSchema } from "./logo.schema";
import LogoSection from "../../components/LogoSection/LogoSection";

export interface APIResponse {
  id: string;
  section: string;
  key: string;
  content: string;
  page: string;
  order: number;
  isPublish: boolean;
}

const Logo = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForPublish, setLoadingForPublish] = useState<boolean>(false);
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
  const [logoPageData, setLogoPageData] = useState<APIResponse[]>([]);
  const [isPublish, setIsPublish] = useState<boolean>(true);
  const apiService = new ApiService();
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const methods = useForm({
    resolver: yupResolver(logoSchema),
  });
  const { handleSubmit, formState } = methods;

  const CloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    // Listen for changes in any form field
    setIsFormEdited(formState.isDirty);
  }, [formState]);
  const getLogoPageData = async () => {
    const payload = {
      url: `pages/${Page.LOGO}`,
      headerToken: localStorageUser.data.accessToken,
    };
    const response = await apiService.get(payload);
    const data = response?.data?.result;
    setLogoPageData(data);

    if (data.length) {
      setIsPublish(data[0].isPublish);
      const resetObject: { [key: string]: any } = {};
      data.forEach((item: any) => {
        resetObject[item.key] = item.content;
      });
      methods.reset(resetObject);
    } else {
      setIsPublish(true);
    }
  };

  useEffect(() => {
    getLogoPageData();
  }, []);

  const handleSave = async (data: FieldValues) => {
    const { logoImage } = data;

    const imagesToUpload = [{ type: "logoImage", image: logoImage }];

    if (!logoPageData.length) {
      try {
        setLoading(true);

        // Generate signed URLs for each image
        const signedUrls = await Promise.all(
          imagesToUpload.map(async (imageObject) => {
            const { image } = imageObject;
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // Banner section
          {
            section: LogoSections.LOGO_SECTION,
            key: LogoHeading.LOGO_TITILE,
            content: data[LogoHeading.LOGO_TITILE],
            page: Page.LOGO,
          },
          {
            section: LogoSections.LOGO_SECTION,
            key: LogoHeading.LOGO_IMAGE,
            content:
              CloudFrontURL +
                signedUrls.find((url) => url.type === LogoHeading.LOGO_IMAGE)
                  ?.key || "",
            page: Page.LOGO,
          },
        ];

        // Upload images
        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );

        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.LOGO}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        methods.reset();
        getLogoPageData();

        toast.success("Data uploaded successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    } else {
      const nonStringImages = imagesToUpload.filter(
        (image) => typeof image.image !== "string"
      );

      try {
        setLoading(true);
        const signedUrls = await Promise.all(
          nonStringImages.map(async (imageObject) => {
            const { image } = imageObject;
            const imageKey = data[image];
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // Banner section

          {
            id: logoPageData.find(
              (item) => item.key === LogoHeading.LOGO_TITILE
            )?.id,
            section: LogoSections.LOGO_SECTION,
            key: LogoHeading.LOGO_TITILE,
            content: data[LogoHeading.LOGO_TITILE],
            page: Page.LOGO,
          },

          {
            id: logoPageData.find((item) => item.key === LogoHeading.LOGO_IMAGE)
              ?.id,
            section: LogoSections.LOGO_SECTION,
            key: LogoHeading.LOGO_IMAGE,
            content: signedUrls?.find(
              (url) => url.type === LogoHeading.LOGO_IMAGE
            )
              ? CloudFrontURL +
                signedUrls?.find((url) => url.type === LogoHeading.LOGO_IMAGE)
                  ?.key
              : logoPageData.find((item) => item.key === LogoHeading.LOGO_IMAGE)
                  ?.content || "",
            page: Page.LOGO,
          },
        ];

        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );
        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.LOGO}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        getLogoPageData();
        toast.success("Data updated successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePublish = async () => {
    setLoadingForPublish(true);
    try {
      const payload = {
        url: `publish/pages/${Page.LOGO}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.post(payload);
      toast.success("Published successfully");
      getLogoPageData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoadingForPublish(false);
    }
  };

  return (
    <div className={`${styles.home}`}>
      <div
        className={`flex align-center justify-between ${styles.home__header}`}
      >
        <h2>Logo</h2>
        <div className={`flex align-center ${styles.home__button}`}>
          <Button
            label="Save"
            type="submit"
            buttonClass="md"
            loading={loading}
            disabled={loading || !isFormEdited}
            onClick={handleSubmit(handleSave)}
          />

          <Button
            label="Publish"
            type="submit"
            buttonClass="md"
            loading={loadingForPublish}
            disabled={isPublish}
            onClick={handlePublish}
          />
        </div>
      </div>

      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          event.preventDefault()
        }
        noValidate
      >
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="Logo">
              <LogoSection />
            </Accordion>
          </FormProvider>
        </div>
      </form>
      {loading && <GlobalLoader />}
    </div>
  );
};

export default Logo;
