import { useFormContext } from "react-hook-form";
import Input from "../../atoms/Input/Input";

const FooterNavigation = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title 1"
        label="Title 1*"
        register={register}
        registerName="footerTitle1"
        errorMessage={errors?.footerTitle1?.message}
      />
      <Input
        type="text"
        placeholder="Title 2"
        label="Title 2*"
        register={register}
        registerName="footerTitle2"
        errorMessage={errors?.footerTitle2?.message}
      />
      <Input
        type="text"
        placeholder="Title 3"
        label="Title 3*"
        register={register}
        registerName="footerTitle3"
        errorMessage={errors?.footerTitle3?.message}
      />
      <Input
        type="text"
        placeholder="Title 4"
        label="Title 4*"
        register={register}
        registerName="footerTitle4"
        errorMessage={errors?.footerTitle4?.message}
      />
    </div>
  );
};

export default FooterNavigation;
