import React, { useMemo } from "react";
import PrevIcon from "../../assets/images/arrow-left.svg";
import NextIcon from "../../assets/images/arrow-right.svg";
import style from "./Pagination.module.scss";

interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  total: number;
  limit: number;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  totalPages,
  onPageChange,
  total,
  limit,
}) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const memoizedValues = useMemo(() => {
    if (totalPages < page) {
      handlePageChange(page - 1);
    }
    const startItem = (page - 1) * limit + 1;
    const endItem = Math.min(page * limit, total);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    return {
      startItem,
      endItem,
      pageNumbers,
    };
  }, [page, limit, total, totalPages]);

  const { startItem, endItem } = memoizedValues;

  const renderPaginationButton = () => {
    const buttons = [];
    buttons.push(
      <li
        key={1}
        onClick={() => handlePageChange(1)}
        className={page === 1 ? style.active : ""}
      >
        1
      </li>
    );
    if (page > 3) {
      buttons.push(<li className={style.isMore}>...</li>);
    }
    let startPage = Math.max(page - 1, 2);
    let endPage = Math.min(page + 1, totalPages - 1);
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li
          key={i}
          onClick={() => handlePageChange(i)}
          className={page === i ? style.active : ""}
        >
          {i}
        </li>
      );
    }
    if (page < totalPages - 2) {
      buttons.push(
        <li className={style.isMore} key="dots2">
          ...
        </li>
      );
    }
    buttons.push(
      <li
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={page === totalPages ? style.active : ""}
      >
        {totalPages}
      </li>
    );

    return buttons;
  };

  return (
    <div
      className={`${style.pagination} flex-wrap justify-between align-center`}
    >
      {total !== 0 && (
        <p>{`Showing ${startItem}-${endItem} out of ${total} ${
          total > 1 ? "results" : "result"
        }`}</p>
      )}
      {total > limit && (
        <nav className="flex align-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            <img src={PrevIcon} alt="Prev" />
          </button>
          <ul className="flex align-center">{renderPaginationButton()}</ul>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            <img src={NextIcon} alt="Next" />
          </button>
        </nav>
      )}
    </div>
  );
};

export default Pagination;
