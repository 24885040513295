import React from "react";
import style from "./Table.module.scss";
import {
  ActionType,
  TableDataType,
  TableHeaderProps,
  TableProps,
} from "../../interfaces/interface";
import TrashIcon from "../../assets/images/trash.svg";
import EditIcon from "../../assets/images/edit.svg";
import { formatPhoneNumber } from "../../constants/constants";

const renderIcon = (actionName: string) => {
  switch (actionName) {
    case ActionType.DELETE:
      return <img src={TrashIcon} alt={actionName} />;
    case ActionType.EDIT:
      return <img src={EditIcon} alt={actionName} />;
    default:
      return null;
  }
};

export const dateConversion = (date: string) => {
  if (date) {
    const dateValue = new Date(date);
    const formattedDate = dateValue ? dateValue.toLocaleDateString() : "-";
    return formattedDate;
  } else {
    return "-";
  }
};

const Table: React.FC<TableProps> = ({
  headings,
  tableData,
  outerBorder = true,
  verticalScroll = false,
  scrollHeight = "none",
  selectedId = "",
  selectedfieldName = "",
}) => {
  const renderCell = (row: any, column: TableHeaderProps) => {
    const {
      name,
      type,
      customClass,
      customStyle,
      format,
      actionType,
      disabled = false,
      onClick = () => {},
      onChange = () => {},
    } = column;

    switch (type) {
      case TableDataType.ACTIONS:
        return (
          <td key={name} className={customClass} style={customStyle}>
            <div className={`flex justify-center ${style.dataBlock__action}`}>
              {actionType?.map((action, actionIndex) => {
                return (
                  <button
                    key={actionIndex}
                    onClick={() => {
                      if (action.onClick) {
                        action.onClick(row);
                      }
                    }}
                    className={action.customClass}
                    style={action.customStyle}
                  >
                    {renderIcon(action.name)}
                  </button>
                );
              })}
            </div>
          </td>
        );
      case TableDataType.DATE:
        return (
          <td
            key={name}
            className={customClass}
            style={customStyle}
            onClick={() => onClick(row)}
          >
            <div className={`${style.dataBlock__data}`}>
              {dateConversion(row[name])}
            </div>
          </td>
        );
      case TableDataType.PHONE_NUMBER:
        return (
          <td
            key={name}
            className={customClass}
            style={customStyle}
            onClick={() => onClick(row)}
          >
            <div className={`${style.dataBlock__data}`}>
              {formatPhoneNumber(row[name])}
            </div>
          </td>
        );

      default:
        return (
          <td
            key={name}
            className={customClass}
            style={customStyle}
            onClick={() => onClick(row)}
          >
            <div className={`${style.dataBlock__data}`}>{row[name] ?? "-"}</div>
          </td>
        );
    }
  };

  return (
    <div
      className={`${style.dataBlock} ${!outerBorder && style.noBorder} ${
        verticalScroll && style.isVerticalScroll
      }`}
    >
      <div
        className={style.dataBlock__scroll}
        style={{
          maxHeight: scrollHeight,
        }}
      >
        <div className={style.dataBlock__wrap}>
          <table>
            <thead>
              <tr>
                {headings.map((header, index) => (
                  <th key={index}>
                    <div className="flex align-center">{header?.title}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={
                      selectedId && selectedId === row[selectedfieldName]
                        ? style.active
                        : ""
                    }
                  >
                    {headings.map((column) => renderCell(row, column))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={25}>
                    <div className={style.dataBlock__nodata}>
                      No Data Available
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
