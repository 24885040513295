import React, { useEffect, useRef, useState } from "react";
import { Control, useController } from "react-hook-form";
import style from "./SearchLocation.module.scss";
import debounce from "lodash/debounce";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

interface DropDownDefaultType {
  value?: string;
  label?: string | number;
}

interface DropdownProps {
  label?: string;
  name?: string;
  control?: Control<any>;
  lightMode?: boolean;
  multiSelection?: boolean;
  error?: string;
  isDisable?: boolean;
  defaultValue?: any;
  placeHolder: string;
}

const SearchLocation: React.FC<DropdownProps> = ({
  label,
  name = "",
  control,
  error,
  isDisable = false,
  defaultValue,
  placeHolder,
}) => {
  const { field } = useController({
    name,
    control,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [noDataShow, setNoDataShow] = useState(true);
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [blur, setBlur] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? "");
  const [debounceString, setDebounceString] = useState<string>("");
  const [hasSelection, setHasSelection] = useState<boolean>(false);
  const debouncedLoadOptions = debounce(async (inputValue) => {
    setIsLoading(true);
    if (!inputValue?.trim()) {
      return;
    }
    setDebounceString(inputValue);
    try {
      const response = await fetch(
        `https://api.nationalelectricalregistry.com/geo/search?search=${inputValue}`
      );
      setNoDataShow(false);
      if (response.ok) {
        const data = await response.json();

        const options = data.addresses.map((address: any) => ({
          label: address.description,
          value: address.location,
        }));

        setOptions(options);
      } else {
        console.error("Failed to fetch options:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setIsLoading(false);
    }
  }, 300);
  function handleOpen(e: any) {
    if (!blur) {
      setOpen(!open);
    }
  }
  const handleInputChange = (selected: any) => {
    const newValue = selected[0];
    field.onChange(newValue);

    setInputValue(newValue.label);
    setHasSelection(true);
    setOpen(false);
    setOptions([]);
  };
  useEffect(() => {
    if (!inputValue?.trim()) {
      field.onChange({});
      setOptions([]);
    } else {
      debouncedLoadOptions(inputValue?.trim());
    }

    return () => debouncedLoadOptions.cancel();
  }, [inputValue]);
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      if (!hasSelection) {
        field.onChange({});
      }
    }
  }, [hasSelection]);
  useEffect(() => {
    if (defaultValue) {
      setHasSelection(true);
      setInputValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <DropdownMenu menuState={setOpen}>
      <div className={`${style.dropdownField}`} onClick={handleOpen}>
        {label && <label>{label}</label>}
        <input
          type="text"
          // {...field}
          className="select-dropdown"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setNoDataShow(true);
            if (e.target.value) setHasSelection(false);
            // setSearched(false);
          }}
          onFocus={() => {
            setOpen(true);
            setBlur(true);
          }}
          placeholder={placeHolder}
        />

        {inputValue.trim() &&
          !noDataShow &&
          !options?.length &&
          !hasSelection &&
          open &&
          !isLoading && (
            <ul className={`${style.dropdownField__list}`}>
              <li>No data found</li>
            </ul>
          )}

        <ul
          className={`${
            options?.length !== 0 &&
            !isLoading &&
            inputValue.trim() &&
            open &&
            style.dropdownField__list
          }`}
        >
          {open &&
            inputValue.trim() &&
            !isLoading &&
            options.map((option, index) => (
              <li key={index} onClick={() => handleInputChange([option])}>
                {option.label}
              </li>
            ))}
        </ul>
        {isLoading && open && (
          <ul className={`${style.dropdownField__list}`}>
            <li>
              <div className={`${style.dropdownField__skeleton}`}>
                <Skeleton height="15px" margin="0 0 10px" />
                <Skeleton height="15px" margin="0 " />
              </div>
            </li>
          </ul>
        )}
      </div>
      {error && <span className={style.dropdownField__error}>{error}</span>}
    </DropdownMenu>
  );
};

export default SearchLocation;
