import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import { FormProvider, useForm } from "react-hook-form";
import { getSignedUrl } from "../../constants/commonFunctions";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  categoryIdData,
  createCategory,
  updateCategoryFn,
} from "../../services/categories.service";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCategorySchema } from "./createCategory.schema";
import style from "./CreateCategory.module.scss";
import Skeleton from "../../atoms/Skeleton/Skeleton";
import { StorageEnums } from "../../enums/storageEnums";
import FileInput from "../../atoms/FileInput/FileInput";
import GlobalLoader from "../../atoms/GlobalLodaer/GlobalLoader";

const CreateCategory = () => {
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
  const methods = useForm({
    resolver: yupResolver(createCategorySchema),
  });
  const {
    formState: { errors },
    register,
    handleSubmit,
    getValues,
    control,
    formState,
  } = methods;

  useEffect(() => {
    // Listen for changes in any form field
    setIsFormEdited(formState.isDirty);
  }, [formState]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const loc = pathname.split("/")[3];
  const id = pathname.split("/")[4];
  const [iconKey, setIconKey] = useState<string>("");
  const [imageKey, setImageKey] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );
  const CloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;

  const updateFiles = async (file: any, type: string): Promise<string> => {
    const payload: {
      fileName: string;
      contentType: string;
    } = {
      fileName: file?.name,
      contentType: file?.type,
    };
    return new Promise(async (res, rej) => {
      try {
        setLoading(true);
        const { data } = await getSignedUrl(payload);
        await axios.put(data.uploadUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
        res(data?.key);
      } catch (error) {
        console.error("error in creating signed url", error);
        rej("failed");
      }
    });
  };

  const getCategoryIdData = async () => {
    try {
      setLoading(true);
      const response = await categoryIdData(id, localStorageUser);
      if (response?.data) {
        const resetObject = {
          categoryName: response?.data?.name,
          categoryIcon: `${response?.data?.icon}`,
          categoryImage:
            response?.data?.image.endsWith(".jpg") ||
            response?.data?.image.endsWith(".jpeg") ||
            response?.data?.image.endsWith(".svg") ||
            response?.data?.image.endsWith(".png")
              ? `${response?.data?.image}`
              : "",
        };
        methods.reset(resetObject);
      }
      setImageKey(response?.data.image);
      setIconKey(response?.data.icon);
    } catch (error: any) {
      if (
        error?.response?.data?.statusCode == 400 ||
        error?.response?.data?.message == "Not found"
      ) {
        navigate("/dashboard/members");
        toast.error("No category exist");
      }
      console.error("error in fetching category of id", error);
    } finally {
      setLoading(false);
    }
  };

  const createCategoryFn = async (keyIcon: string, keyImage: string) => {
    const newCategoryData = {
      name: getValues("categoryName"),
      icon: CloudFrontURL + keyIcon,
      image: CloudFrontURL + keyImage,
    };
    try {
      await createCategory(newCategoryData, localStorageUser);
      toast.success("Category created successfully");
      navigate("/dashboard/categories");
    } catch (error) {
      console.error("error is saving a new categoring", error);
      toast.error("An error occurred while creating the category");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    let keyIcon = "",
      keyImage = "";
    keyIcon = (getValues("categoryIcon") as { name: string })?.name
      ? await updateFiles(getValues("categoryIcon"), "icon")
      : getValues("categoryIcon")
      ? iconKey
      : "";
    keyImage = (getValues("categoryImage") as { name: string })?.name
      ? await updateFiles(getValues("categoryImage"), "image")
      : getValues("categoryImage")
      ? imageKey
      : "";
    let data = {
      name: getValues("categoryName"),
      icon: keyIcon.includes(CloudFrontURL as string)
        ? keyIcon
        : CloudFrontURL + keyIcon,
      image: keyImage.includes(CloudFrontURL as string)
        ? keyImage
        : CloudFrontURL + keyImage,
    };

    if (loc === "create") {
      createCategoryFn(keyIcon, keyImage);
    } else {
      try {
        setLoading(true);
        await updateCategoryFn(id, data, localStorageUser);
        toast.success("Category updated successfully");
        navigate("/dashboard/categories");
      } catch (error) {
        console.error("error in updating category", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getCategoryIdData();
    }
  }, [id]);

  return (
    <>
      <FormProvider {...methods}>
        <div className={style.createCategory}>
          <div
            className={`${style.createCategory__head} flex-wrap justify-between align-center`}
          >
            <h2>{loc === "edit" ? "Edit Category" : "Add New Category"}</h2>
            <div className={`${style.createCategory__footer} flex justify-end`}>
              <Link to="/dashboard/categories">
                <Button buttonClass="outline md" label="Back" />
              </Link>
              <Button
                disabled={loading}
                loading={loading}
                label="Save"
                type="submit"
                buttonClass="md"
                onClick={handleSubmit(handleSave)}
              />
            </div>
          </div>

          <div className={style.createCategory__content}>
            {loading ? (
              <>
                <Skeleton height={"15px"} width="150px" margin="0 0 10px" />
                <Skeleton height={"50px"} margin="0 0 20px" />
                <div className={`${style.createCategory__upload} flex-wrap`}>
                  <div className={style.createCategory__uploadItem}>
                    <Skeleton height={"15px"} width="150px" margin="0 0 10px" />
                    <Skeleton height={"195px"} margin="0" />
                  </div>
                  <div className={style.createCategory__uploadItem}>
                    <Skeleton height={"15px"} width="150px" margin="0 0 10px" />
                    <Skeleton height={"195px"} margin="0 0 30px" />
                  </div>
                </div>
              </>
            ) : (
              <div>
                <Input
                  type="text"
                  placeholder="Category Name"
                  label="Category Name*"
                  register={register}
                  registerName="categoryName"
                  errorMessage={errors?.categoryName?.message}
                />

                <div className={`${style.createCategory__upload} flex-wrap`}>
                  <div className={style.createCategory__uploadItem}>
                    <FileInput
                      label="Upload Icon*"
                      register={register}
                      registerName="categoryIcon"
                      disabled={false}
                      accept="image/png,image/jpeg,image/jpg,image/svg+xml"
                      control={control}
                      errorMessage={errors?.categoryIcon?.message}
                      value={getValues("categoryIcon") ?? ""}
                    />
                  </div>
                  <div className={style.createCategory__uploadItem}>
                    <FileInput
                      label="Upload Image"
                      register={register}
                      registerName="categoryImage"
                      disabled={false}
                      accept="image/png,image/jpeg,image/jpg,image/svg+xml"
                      control={control}
                      errorMessage={errors?.categoryImage?.message}
                      value={getValues("categoryImage") ?? ""}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </FormProvider>
      {loading && <GlobalLoader />}
    </>
  );
};
export default CreateCategory;
