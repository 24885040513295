

export interface CustomButtonProps {
  type?: "button" | "submit" | "reset";
  buttonClass?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  children?: any;
  onClick?: (e?: any) => void;
}

export interface CustomInputProps {
  label?: string;
  type?: string;
  value?: any;
  placeholder?: string;
  errorMessage?: any;
  register?: any;
  registerName?: string;
  control?: any;
  disabled?: boolean;
  min?: string;
  marginBottom?: boolean;
  readOnly?: boolean;
  prefixIcon?: string;
  accept?:string;
  multiple?:boolean
}


export interface SidebarTab {
  name: string;
  icon?: any;
  path: string;
}
export interface ApiDataObject {
  url: string;
  data?: any;
  headerToken?: string;
  Authorization?: String;
  PresignedUrl?: boolean;
  contentType?: string;
  params?: object;
  refreshToken?:string;
  options?:any
}

// Table Interfaces
export interface TableHeaderProps extends TableActionType {
  name: string;
  type: TableDataType;
  actionType?: TableActionType[];
  format?: string;
  title?: string;
  handleSort?: (columnName: string) => void;
}

export enum TableDataType {
  TEXT = "text",
  NUMBER = "number",
  PHONE_NUMBER = "phone_number",
  ACTIONS = "action",
  CHECKBOX = "checkbox",
  DATE = "date",
  TOGGLE = "toggle",
  INPUT = "input",
  BOOLEAN = "boolean",
  ICON = "string"
}

export enum ActionType {
  DELETE = "DELETE",
  ADD = "ADD",
  VIEW = "VIEW",
  EDIT = "EDIT",
}

export interface TableActionType {
  icon?: string;
  name: ActionType | string;
  onClick?: (rowData: any) => void;
  onChange?: (rowData: any, value: string, keyThatIsChanged: string) => void;
  customClass?: string;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
}

export interface TableProps {
  headings: TableHeaderProps[];
  tableData: any[];
  outerBorder?: boolean;
  verticalScroll?: boolean;
  scrollHeight?: any;
  selectedId?: string | number | null | undefined;
  selectedfieldName?: string;
}