import React from "react";
import Input from "../../atoms/Input/Input";
import FileInput from "../../atoms/FileInput/FileInput";

interface ContactUsProps {
  cardNumber: number;
  errors: any;
  register: any;
  control: any;
  getValues: any;
}

const ContactUs: React.FC<ContactUsProps> = ({
  cardNumber,
  errors,
  register,
  control,
  getValues,
}) => {
  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        label={`Card ${cardNumber} Title*`}
        placeholder={`Card ${cardNumber} Title`}
        register={register}
        registerName={`card${cardNumber}Title`}
        errorMessage={errors[`card${cardNumber}Title`]?.message}
      />
      <Input
        type="textarea"
        label={`Card ${cardNumber} Description*`}
        placeholder={`Card ${cardNumber} Description`}
        register={register}
        registerName={`card${cardNumber}Description`}
        errorMessage={errors[`card${cardNumber}Description`]?.message}
      />
      <FileInput
        value={getValues(`card${cardNumber}Icon`) ?? ""}
        label={`Card ${cardNumber} Icon*`}
        register={register}
        registerName={`card${cardNumber}Icon`}
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors[`card${cardNumber}Icon`]?.message}
      />
    </div>
  );
};

export default ContactUs;
