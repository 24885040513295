import { useEffect, useMemo, useState } from "react";
import Button from "../../atoms/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import MemberList from "../../components/MemberList/MemberList";
import style from "./Members.module.scss";
import TableLoader from "../../components/Table/TableLoader";
import { MEMBERS_HEADINGS } from "../../components/MemberList/memberList.constants";
import { TableDataType } from "../../interfaces/interface";
import { ActionType } from "../../interfaces/interface";
import { deleteMember, getMembersData } from "../../services/member.service";
import { StorageEnums } from "../../enums/storageEnums";
import { CustomError } from "../../interfaces/categories.interface";
import { PagingParameters } from "../ContactDetails/ContactDetails";
import CustomModal from "../../components/CustomModal/CustomModal";
import Confirmation from "../../components/Confirmation/Confirmation";
import { toast } from "react-toastify";
import { ApiService } from "../../services/apiServices";
import Pagination from "../../components/Pagination/Pagination";

const Members = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForDelete, setLoadingForDelete] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>("");
  const [memberList, setMemberList] = useState([]);
  const [pagingParameters, setPagingParameters] = useState<PagingParameters>({
    pageNumber: 1,
    recordsPerPage: 10,
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<string>("");
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const apiService = new ApiService();

  const handleDelete = async (data: any) => {
    setConfirmationModalVisible(true);
    setDeleteId(data.id);
  };
  const handleConfirmDelete = async () => {
    setConfirmationModalVisible(false);
    setLoadingForDelete(true);
    try {
      await deleteMember(deleteId, localStorageUser);
      toast.success("Member deleted successfully");
      getMembers(1);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingForDelete(false);
    }
  };

  const handleEdit = (rowData: any) => {
    navigate(`edit/${rowData?.id}`);
  };

  const getMembers = async (page: any) => {
    try {
      setLoading(true);
      const payload = {
        url: `members?page=${page}&limit=10&search=${searchQuery.trim()}`,
        headerToken: localStorageUser.data.accessToken,
      };
      let response = await apiService.get(payload);

      // const response = await getMembersData(localStorageUser);

      const data = response?.data?.result;
      setTotalCount(response.data.count);

      data.forEach((obj: any) => {
        obj.categories = obj.categories
          .map((category: any) => category.name)
          .join(", ");
      });

      setMemberList(response?.data?.result);
    } catch (error) {
      console.error(
        "error in getting Members table data",
        (error as CustomError)?.response?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };
  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };
  function handlePageChange(page: number) {
    setPagingParameters((prevParameters) => ({
      ...prevParameters,
      pageNumber: page,
    }));
  }
  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value);
    // Reset page number to 1 when initiating a new search
    handlePageChange(1);
  }

  const memberListHeaders = useMemo(() => {
    return MEMBERS_HEADINGS.map((el: any) => {
      if (el.type === TableDataType.ACTIONS) {
        el?.actionType?.forEach((e: any) => {
          if (e.name === ActionType.DELETE) {
            e.onClick = handleDelete;
          }
          if (e.name === ActionType.EDIT) {
            e.onClick = handleEdit;
          }
        });
      }
      return el;
    });
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery.trim());
    }, 500); // Adjust the delay time as needed
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);
  useEffect(() => {
    getMembers(pagingParameters.pageNumber);
  }, [pagingParameters.pageNumber, debouncedSearchQuery]);
  return (
    <div>
      <div className={style.categories}>
        <div
          className={`${style.categories__header} flex-wrap align-center justify-between`}
        >
          <h2>Members</h2>
          <Link to="create">
            <span>+ Add</span>
            <Button buttonClass="md" label="+ Add New Member" />
          </Link>
        </div>
        <div className={`${style.categories__search}`}>
          <input
            type="text"
            placeholder="Search by name or location  "
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        {loading ? (
          <>
            <TableLoader
              rows={8}
              headings={memberListHeaders}
              columns={memberListHeaders?.length}
            />
          </>
        ) : (
          <MemberList memberList={memberList} heading={memberListHeaders} />
        )}
        <Pagination
          page={pagingParameters?.pageNumber}
          totalPages={Math.ceil(totalCount / pagingParameters.recordsPerPage)}
          onPageChange={handlePageChange}
          total={totalCount}
          limit={pagingParameters.recordsPerPage}
        />
      </div>
      <CustomModal
        maxWidth="480px"
        title=""
        modalVisible={isConfirmationModalVisible}
        onClose={handleCloseConfirmationModal}
        footerNode={
          <>
            <Button
              buttonClass="md outline"
              onClick={handleCloseConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              buttonClass="md"
              disabled={loadingForDelete}
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
          </>
        }
      >
        <Confirmation
          title="Delete Member"
          description="Are you sure you want to delete this Member ?"
        />
      </CustomModal>
    </div>
  );
};

export default Members;
