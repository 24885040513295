import React from "react";
// import InfoIcon from "../../assets/images/info.svg";
import style from "./Confirmation.module.scss";
export interface ConfirmationProps {
  icon?: any;
  title: string;
  description: string;
}

const Confirmation = ({ title, description }: ConfirmationProps) => {
  return (
    <div className={style.confirmation}>
          <h3>{title}</h3>
          <p>{description}</p>
    </div>
  );
};
export default Confirmation;
