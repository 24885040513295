import {
  CreateCategoryType,
  LocalStorageUserType,
} from "../interfaces/categories.interface";
import { ApiService } from "./apiServices";

const apiService = new ApiService();
export const getCategoryListData = (localStorageUser: LocalStorageUserType) => {
  let payload = {
    url: `categories`,
    headerToken: localStorageUser?.data.accessToken,
  };
  return apiService.get(payload);
};

export const createCategory = (
  data: CreateCategoryType,
  localStorageUser: LocalStorageUserType
) => {
  let payload = {
    url: `categories`,
    headerToken: localStorageUser.data.accessToken,
    data: data,
  };
  return apiService.post(payload);
};

export const deleteCategory = (
  id: string,
  localStorageUser: LocalStorageUserType
) => {
  let payload = {
    url: `categories/${id}`,
    headerToken: localStorageUser.data.accessToken,
  };
  return apiService.delete(payload);
};

export const categoryIdData = (
  id: string,
  localStorageUser: LocalStorageUserType
) => {
  let payload = {
    url: `categories/${id}`,
    headerToken: localStorageUser.data.accessToken,
  };
  return apiService.get(payload);
};

export const updateCategoryFn = (
  id: string,
  data: CreateCategoryType,
  localStorageUser: LocalStorageUserType
) => {
  let payload = {
    url: `categories/${id}`,
    headerToken: localStorageUser.data.accessToken,
    data: data,
  };
  return apiService.put(payload);
};
