import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../atoms/Button/Button";
import styles from "./Header.module.scss";
import Accordion from "../../components/Accordian/Accordian";

import { ApiService } from "../../services/apiServices";
import {
  HeaderHeading,
  HeaderSections,
  Page,
  StorageEnums,
} from "../../enums/storageEnums";
import GlobalLoader from "../../atoms/GlobalLodaer/GlobalLoader";
import HeaderLogo from "../../components/HeaderLogo/HeaderLogo";
import { headerSchema } from "./header.schema";
import HeaderNavigation from "../../components/HeaderNavigation/HeaderNavigation";
import { getSignedUrl, uploadImage } from "../../constants/commonFunctions";
import { APIResponse } from "../Home/Home";
import { toast } from "react-toastify";

const Header = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForPublish, setLoadingForPublish] = useState<boolean>(false);
  const [headerPageData, setHeaderPageData] = useState<APIResponse[]>([]);
  const [isPublish, setIsPublish] = useState<boolean>(true);
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

  const apiService = new ApiService();
  const CloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const methods = useForm({
    resolver: yupResolver(headerSchema),
  });
  const { handleSubmit, watch, formState, getValues } = methods;
  useEffect(() => {
    getHeaderPageData();
  }, []);

  useEffect(() => {
    setIsFormEdited(formState.isDirty);
  }, [formState]);

  const getHeaderPageData = async () => {
    const payload = {
      url: `pages/${Page.HEADER}`,
      headerToken: localStorageUser.data.accessToken,
    };
    const response = await apiService.get(payload);
    const data = response?.data?.result;
    setHeaderPageData(data);

    if (data.length) {
      setIsPublish(data[0].isPublish);
      const resetObject: { [key: string]: any } = {};
      data.forEach((item: any) => {
        resetObject[item.key] = item.content;
      });
      methods.reset(resetObject);
    } else {
      setIsPublish(true);
    }
  };

  const updateHeaderData = async (data: FieldValues) => {
    const { logo } = data;
    setLoading(true);
    let res;
    try {
      if (typeof logo !== "string") {
        const imageInfo = {
          fileName: logo.name,
          contentType: logo.type,
        };
        res = await getSignedUrl(imageInfo);
        await uploadImage(logo, res.data.uploadUrl);
      }
      const dataToUpload: any[] = [
        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_BANNER_TITLE
          )?.id,
          section: HeaderSections.HEADER_SECTION_1,
          key: HeaderHeading.HEADER_BANNER_TITLE,
          content: data[HeaderHeading.HEADER_BANNER_TITLE],
          page: Page.HEADER,
        },
        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_BANNER_TITLE1
          )?.id,
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE1,
          content: data[HeaderHeading.HEADER_BANNER_TITLE1],
          page: Page.HEADER,
        },
        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_BANNER_TITLE2
          )?.id,
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE2,
          content: data[HeaderHeading.HEADER_BANNER_TITLE2],
          page: Page.HEADER,
        },
        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_BANNER_URL
          )?.id,
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_URL,
          content: data[HeaderHeading.HEADER_BANNER_URL],
          page: Page.HEADER,
        },
        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_BANNER_TITLE3
          )?.id,
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE3,
          content: data[HeaderHeading.HEADER_BANNER_TITLE3],
          page: Page.HEADER,
        },
        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_BANNER_TITLE4
          )?.id,
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE4,
          content: data[HeaderHeading.HEADER_BANNER_TITLE4],
          page: Page.HEADER,
        },
        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_BANNER_TITLE5
          )?.id,
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE5,
          content: data[HeaderHeading.HEADER_BANNER_TITLE5],
          page: Page.HEADER,
        },

        {
          id: headerPageData.find(
            (item) => item.key === HeaderHeading.HEADER_LOGO
          )?.id,
          section: HeaderSections.HEADER_SECTION_1,
          key: HeaderHeading.HEADER_LOGO,
          content: res?.data.key
            ? CloudFrontURL + res?.data.key
            : headerPageData.find(
                (item) => item.key === HeaderHeading.HEADER_LOGO
              )?.content || "",

          page: Page.HEADER,
        },
      ];

      const payload = {
        data: { sections: dataToUpload },
        url: `pages/${Page.HEADER}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.post(payload);
      getHeaderPageData();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const firstTimeSave = async (data: FieldValues) => {
    const { logo } = data;
    setLoading(true);
    try {
      const imageInfo = {
        fileName: logo.name,
        contentType: logo.type,
      };
      const res = await getSignedUrl(imageInfo);
      await uploadImage(logo, res.data.uploadUrl);
      const dataToUpload: any[] = [
        {
          section: HeaderSections.HEADER_SECTION_1,
          key: HeaderHeading.HEADER_BANNER_TITLE,
          content: data[HeaderHeading.HEADER_BANNER_TITLE],
          page: Page.HEADER,
        },
        {
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE1,
          content: data[HeaderHeading.HEADER_BANNER_TITLE1],
          page: Page.HEADER,
        },
        {
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE2,
          content: data[HeaderHeading.HEADER_BANNER_TITLE2],
          page: Page.HEADER,
        },
        {
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_URL,
          content: data[HeaderHeading.HEADER_BANNER_URL],
          page: Page.HEADER,
        },
        {
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE3,
          content: data[HeaderHeading.HEADER_BANNER_TITLE3],
          page: Page.HEADER,
        },
        {
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE4,
          content: data[HeaderHeading.HEADER_BANNER_TITLE4],
          page: Page.HEADER,
        },
        {
          section: HeaderSections.HEADER_SECTION_2,
          key: HeaderHeading.HEADER_BANNER_TITLE5,
          content: data[HeaderHeading.HEADER_BANNER_TITLE5],
          page: Page.HEADER,
        },

        {
          section: HeaderSections.HEADER_SECTION_1,
          key: HeaderHeading.HEADER_LOGO,
          content: CloudFrontURL + res.data.key,
          page: Page.HEADER,
        },
      ];

      const payload = {
        data: { sections: dataToUpload },
        url: `pages/${Page.HEADER}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.post(payload);
      getHeaderPageData();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (data: FieldValues) => {
    if (!headerPageData) await firstTimeSave(data);
    else await updateHeaderData(data);
  };

  const handlePublish = async () => {
    setLoadingForPublish(true);
    try {
      const payload = {
        url: `publish/pages/${Page.HEADER}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.post(payload);
      toast.success("Published successfully");
      getHeaderPageData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoadingForPublish(false);
    }
  };

  return (
    <div className={`${styles.home}`}>
      <div
        className={`flex align-center justify-between ${styles.home__header}`}
      >
        <h2>Header</h2>
        <div className={`flex align-center ${styles.home__button}`}>
          <Button
            label="Save"
            type="submit"
            buttonClass="md"
            loading={loading}
            onClick={handleSubmit(handleSave)}
            disabled={loading || !isFormEdited}
          />

          <Button
            label="Publish"
            type="submit"
            buttonClass="md"
            loading={loadingForPublish}
            disabled={isPublish}
            onClick={handlePublish}
          />
        </div>
      </div>

      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          event.preventDefault()
        }
        noValidate
      >
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="Header ">
              <HeaderLogo />
            </Accordion>
          </FormProvider>

          <FormProvider {...methods}>
            <Accordion title="Navigation ">
              <HeaderNavigation />
            </Accordion>
          </FormProvider>
        </div>
      </form>
      {loading && <GlobalLoader />}
    </div>
  );
};

export default Header;
