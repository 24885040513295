import React, { useEffect } from "react";
import { Control, useController } from "react-hook-form";
import Select from "react-select";
import style from "./dropdown.module.scss";

interface DropDownDefaultType {
  value?: string;
  label?: string | number;
}
interface DropdownProps {
  label?: string;
  name?: string;
  options?: { value: string | number; label?: string }[];
  control?: Control<any>;
  lightMode?: boolean;
  multiSelection?: boolean;
  error?: string;
  isDisable?: boolean;
  defaultValue?: DropDownDefaultType;
  placeHolder: string;
}

const CategoryDropdown: React.FC<DropdownProps> = ({
  label,
  name = "",
  options,
  multiSelection = false,
  lightMode = false,
  control,
  error,
  isDisable = false,
  defaultValue,
  placeHolder,
}) => {
  const { field } = useController({
    name,
    control,
  });

  useEffect(() => {
    if (!field.value && defaultValue && defaultValue.value) {
      selectInputRef.current.setValue({
        label: defaultValue.label || "",
        value: defaultValue.value,
      });
    }
  }, [field.value, defaultValue]);

  const selectInputRef = React.useRef<any>();

  React.useEffect(() => {
    if (defaultValue) {
      selectInputRef.current.setValue({
        label: defaultValue.label,
        value: defaultValue.value,
      });
    }
  }, [defaultValue]);
  return (
    <div className={`${style.dropdownField} ${isDisable && style.isDisable}`}>
      {label && <label className={style.dropdownField__label}>{label}</label>}
      <Select
        placeholder={placeHolder}
        // className={`select-dropdown ${lightMode ? "is-light" : ""}`}
        {...field}
        classNamePrefix="select-dropdown"
        isMulti={multiSelection}
        options={options}
        value={field?.value?.label ? field?.value : null}
        ref={selectInputRef}
      />
      {error && <span className={style.dropdownField__error}>{error}</span>}
    </div>
  );
};

export default CategoryDropdown;
