import React from "react";
import styles from "./NotFoundPage.module.scss";
import Logo from "../../components/Logo/Logo";


const NotFoundPage = () => {
    return <>
     
    <div className={` ${styles.notFound}`}>
    <div className={`${styles.notFound__header}`}>
    <Logo logoBlack/>
    </div>
    <div className={`flex align-center justify-center ${styles.notFound__main}`}>
        <div className={` ${styles.notFound__inner}`}>

            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The page you are looking for might be in another universe. Please check the url or click the button below to go back</p>
            <a className="button md" href="/"> Go back</a>
        </div>
    </div>
    </div>
    </>;
};

export default NotFoundPage;
