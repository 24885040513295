import {
    LocalStorageUserType,
  } from "../interfaces/categories.interface";
  import { ApiService } from "./apiServices";
  
  const apiService = new ApiService();
  export const getMembersData = (localStorageUser: LocalStorageUserType) => {
    let payload = {
      url: `members`,
      headerToken: localStorageUser?.data.accessToken,
    };
    return apiService.get(payload);
  };
  
  export const addMember = (
    data: any,
    localStorageUser: LocalStorageUserType
  ) => {
    let payload = {
      url: `members`,
      headerToken: localStorageUser.data.accessToken,
      data: data,
    };
    return apiService.post(payload);
  };

  export const updateMember = (
    id:string,
    data: any,
    localStorageUser: LocalStorageUserType
  ) => {
    let payload = {
      url: `members/${id}`,
      headerToken: localStorageUser.data.accessToken,
      data: data,
    };
    return apiService.put(payload);
  };
  
  export const deleteMember = (
    id: string,
    localStorageUser: LocalStorageUserType
  ) => {
    let payload = {
      url: `members/${id}`,
      headerToken: localStorageUser.data.accessToken,
    };
    return apiService.delete(payload);
  };
  
  export const memberIdData = (
    id: string,
    localStorageUser: LocalStorageUserType
  ) => {
    let payload = {
      url: `members/${id}`,
      headerToken: localStorageUser.data.accessToken,
    };
    return apiService.get(payload);
  };
  
//   export const updateCategoryFn = (
//     id: string,
//     data: CreateCategoryType,
//     localStorageUser: LocalStorageUserType
//   ) => {
//     let payload = {
//       url: `categories/${id}`,
//       headerToken: localStorageUser.data.accessToken,
//       data: data,
//     };
//     return apiService.put(payload);
//   };
  