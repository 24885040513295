import React, { useState } from "react";
import eyeClose from "../../assets/images/eye-close.svg";
import eyeOpen from "../../assets/images/eye-open.svg";
import { CustomInputProps } from "../../interfaces/interface";
import styles from "./Input.module.scss";

const Input: React.FC<CustomInputProps> = ({
  label,
  type,
  value,
  placeholder,
  errorMessage = "",
  register,
  registerName = "",
  disabled = false,
  min = "",
  marginBottom = true,
  readOnly = false,
  prefixIcon = "",
  accept,
}: CustomInputProps) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <div
      className={`${styles.inputField} ${
        type === "password" && isPasswordVisible
          ? styles.inputFieldPassword
          : ``
      } 
      ${type === "textarea" ? styles.textarea : ``}
      ${errorMessage && styles.inputError}`}
    >
      {label && <label>{label}</label>}
      <div className={`${styles.inputField__input}`}>
        <>
          {type !== "textarea" ? (
            <input
              type={type === "password" && isPasswordVisible ? "text" : type}
              placeholder={placeholder}
              value={value}
              name={registerName}
              disabled={disabled}
              {...register(registerName)}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              min={min}
              readOnly={readOnly}
              accept={accept}
            />
          ) : (
            <textarea
              type={type}
              placeholder={placeholder}
              value={value}
              name={registerName}
              disabled={disabled}
              {...register(registerName)}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              min={min}
              readOnly={readOnly}
            />
          )}
          {prefixIcon && (
            <div className={`${styles.inputField__prefix}`}>
              <img src={prefixIcon} alt="icon" role="button" />
            </div>
          )}
          {type === "password" && (
            <div className={`${styles.inputField__prefix}`}>
              <img
                src={isPasswordVisible ? eyeOpen : eyeClose}
                alt="eye"
                role="button"
                onClick={togglePasswordVisibility}
              />
            </div>
          )}
        </>
      </div>
      {errorMessage && (
        <span className={`${styles.inputField__error}`}>{errorMessage}</span>
      )}
    </div>
  );
};

export default Input;
