export const maxSize = 99 * 1024 * 1024; // 100MB in bytes
export const allowedTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
];
export const formatPhoneNumber = (inputValue: string) => {
  // Function to format phone number input
  const formattedValue = inputValue?.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  return formattedValue;
};