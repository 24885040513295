import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";
import FileInput from "../../atoms/FileInput/FileInput";

const AboutFounderSection = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
    watch,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="aboutFounderTitle"
        errorMessage={errors?.aboutFounderTitle?.message}
      />
      <Input
        type="text"
        placeholder="Name"
        label="Name*"
        register={register}
        registerName="aboutFounderName"
        errorMessage={errors?.aboutFounderName?.message}
      />
      <Input
        type="text"
        placeholder="Designation"
        label="Designation*"
        register={register}
        registerName="aboutFounderDesignation"
        errorMessage={errors?.aboutFounderDesignation?.message}
      />

      <FileInput
        label="Image*"
        register={register}
        registerName="aboutFounderImage"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.aboutFounderImage?.message}
        value={getValues("aboutFounderImage") ?? ""}
      />

      <Input
        type="textarea"
        placeholder="Description"
        label="Description*"
        register={register}
        registerName="aboutFounderDescription"
        errorMessage={errors?.aboutFounderDescription?.message}
      />
    </div>
  );
};

export default AboutFounderSection;
