import React, { useState, useRef, useEffect } from "react";
import { CustomInputProps } from "../../interfaces/interface";
import { Controller, useFormContext } from "react-hook-form";
import upload from "../../assets/images/upload.svg";
import close from "../../assets/images/cross.svg";
import styles from "./MultipleFilesInput.module.scss";
import { toast } from "react-toastify";
import { allowedTypes, maxSize } from "../../constants/constants";

const MultipleFilesInput: React.FC<CustomInputProps> = ({
  label,
  errorMessage = "",
  registerName = "",
  control,
}) => {
  const [images, setImages] = useState<File[] | any[]>([]);
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const { setValue, clearErrors, trigger, getValues, watch, setError } =
    useFormContext();
  const [shouldFetchImages, setShouldFetchImages] = useState(false);

  useEffect(() => {
    if (!shouldFetchImages) return;
    if (
      getValues(registerName) &&
      getValues(registerName)?.length &&
      typeof getValues(registerName)[0] === "string"
    ) {
      let updatedImages: string[] = [];
      const fetchImages = async () => {
        for (const image of getValues(registerName)) {
          updatedImages.push(image);
        }
        setImages(updatedImages);
      };
      fetchImages();
    }
    if (getValues(registerName) === null) {
      setImages([]);
    }
    // else {
    //   setImages([]);
    // }
    setShouldFetchImages(false);
  }, [getValues(registerName)]);

  useEffect(() => {
    // Trigger fetchImages when registerName changes
    setShouldFetchImages(true);
  }, [registerName]);

  useEffect(() => {
    if (getValues(registerName)) clearErrors(registerName);
  }, [getValues(registerName)]);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      const file = fileList[0];

      const filesArray = Array.from(fileList);
      const invalidFiles = filesArray.filter(
        (file) => !allowedTypes?.includes(file.type)
      );
      const invalidFileSize = filesArray.filter((file) => file.size > maxSize);
      const validFileSize = filesArray.filter((file) => file.size < maxSize);
      if (invalidFileSize.length !== 0) {
        toast.error("File size exceeds 100MB limit");
        setImages((prevImages) => [...prevImages, ...validFileSize]);
        return;
      }
      if (invalidFiles.length > 0) {
        toast.error("Please upload only PNG, JPG, JPEG, or SVG files");
        if (fileUploaderRef.current) {
          fileUploaderRef.current.value = "";
        }
        setValue(registerName, null);
        return;
      }
      setImages((prevImages) => [...prevImages, ...filesArray]);

      clearErrors(registerName);
    }
    if (fileUploaderRef.current) {
      fileUploaderRef.current.value = "";
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      setValue(registerName, images, { shouldDirty: true });
    }
    if (images.length == 0 && watch(registerName) !== undefined) {
      setValue(registerName, null);
      trigger(registerName);
    }
  }, [images]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    if (event.dataTransfer.files.length) {
      const droppedFiles = Array.from(fileList);
      const invalidFileSize = droppedFiles.filter(
        (file) => file.size > maxSize
      );
      const validFileSize = droppedFiles.filter((file) => file.size < maxSize);
      if (invalidFileSize.length !== 0) {
        toast.error("File size exceeds 100MB limit");
        setImages((prevImages) => [...prevImages, ...validFileSize]);
        return;
      }
      const invalidFiles = droppedFiles.filter(
        (file) => !allowedTypes?.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        toast.error("Please upload only PNG, JPG, JPEG, or SVG files");
        if (fileUploaderRef.current) {
          fileUploaderRef.current.value = "";
        }
        return;
      }
      setImages((prevImages) => [...prevImages, ...droppedFiles]);
    }
    if (fileUploaderRef.current) {
      fileUploaderRef.current.value = "";
    }
  };

  const handleDeleteImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    trigger(registerName);
  };

  const handleClickUpload = () => {
    if (fileUploaderRef.current) {
      fileUploaderRef.current.click();
    }
  };

  return (
    <div className={`${styles.fileInput}`}>
      {label && <label htmlFor="fileUploader">{label}</label>}
      <Controller
        name={registerName}
        control={control}
        render={({ field }) => {
          return (
            <input
              type="file"
              name={registerName}
              accept="image/*"
              multiple
              onChange={(e) => {
                if (e.target.files?.length) handleFileInputChange(e);
                field.onChange(e.target.files);
              }}
              style={{ display: "none" }}
              id="fileUploader"
              ref={fileUploaderRef}
            />
          );
        }}
      />
      <div
        onClick={handleClickUpload}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={`flex align-center justify-center ${styles.fileInput__inner}`}
      >
        <div className={`${styles.fileInput__info}`}>
          <figure>
            <img src={upload} alt="upload" />
          </figure>
          <p>
            Click here to upload image or drag and drop your file here(Max file
            size is 100 mb of png, jpg , jpeg or svg type)
          </p>
        </div>
      </div>
      <div className={`flex-wrap ${styles.fileInput__img}`}>
        {images.map((item, index) => (
          <figure key={index}>
            {item instanceof File ? (
              <img src={URL.createObjectURL(item)} alt={`Image ${index}`} />
            ) : (
              <img src={item} alt={`Image ${index}`} />
            )}
            <span role="button" onClick={() => handleDeleteImage(index)}>
              <img src={close} alt="remove" />
            </span>
          </figure>
        ))}
      </div>
      {errorMessage && (
        <div className={`${styles.fileInput__error}`}>{errorMessage}</div>
      )}
    </div>
  );
};

export default MultipleFilesInput;
