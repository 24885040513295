import { NavLink, useNavigate } from "react-router-dom";
import { SidebarTab } from "../../interfaces/interface";
import styles from "./Sidebar.module.scss";
import Logo from "../Logo/Logo";
import close from "../../assets/images/cross.svg";
import { StorageService } from "../../services/storage.service";
import { useState } from "react";
import { HOME_SIDEBAR_OTHERS_TABS } from "../../constants/sidebar.constants";
export interface SidebarProp {
  showNav: boolean;
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
  SidebarTabData: SidebarTab[];
}
const Sidebar = ({ showNav, setShowNav, SidebarTabData }: SidebarProp) => {
  const storageService = new StorageService();
  const navigate = useNavigate();
  const handleLogout = () => {
    storageService.clearLocalStorage();
    navigate("/login");
  };

  const [showOthersDrawer, setShowOthersDrawer] = useState(false);
  return (
    <>
      <div className={`${styles.sidebar} ${showNav && styles.active}`}>
        <div className={` ${styles.sidebar__nav}`}>
          <div
            role="button"
            className={`flex justify-end ${styles.sidebar__close}`}
          >
            <img onClick={() => setShowNav(false)} src={close} alt="close" />
          </div>
          <div className={` ${styles.sidebar__logo}`}>
            <Logo IconOnly />
          </div>

          <ul>
            {SidebarTabData?.map((el: SidebarTab, ind) => {
              return (
                <li
                  onClick={(e) => {
                    if (el.name !== "Others" || showOthersDrawer)
                      setShowNav(false);
                    e.stopPropagation();
                  }}
                  key={ind}
                  className={` ${el.name === "LogOut" && styles.logOut}`}
                >
                  {el.name == "LogOut" && (
                    <div role="button" onClick={handleLogout}>
                      <el.icon />
                      {el.name}
                    </div>
                  )}
                  {el.name == "Others" && (
                    <div
                      role="button"
                      onClick={() => setShowOthersDrawer(!showOthersDrawer)}
                    >
                      <el.icon />
                      {el.name}
                    </div>
                  )}

                  {el.name !== "LogOut" && el.name !== "Others" && (
                    <NavLink key={el.path} to={el.path}>
                      {({ isActive, isPending }) => (
                        <>
                          <el.icon />
                          {el.name}
                        </>
                      )}
                    </NavLink>
                  )}
                  {el.name == "Others" && showOthersDrawer && (
                    <ul>
                      {HOME_SIDEBAR_OTHERS_TABS?.map((el: SidebarTab, ind) => {
                        return (
                          <li key={ind}>
                            <NavLink key={el.path} to={el.path}>
                              {({ isActive, isPending }) => (
                                <>
                                  <el.icon />
                                  {el.name}
                                </>
                              )}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={`${styles.sidebar__overlay}`}
          onClick={(e) => {
            setShowNav(false);
            e.stopPropagation();
          }}
        ></div>
      </div>
    </>
  );
};
export default Sidebar;
