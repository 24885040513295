import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../atoms/Button/Button";
import styles from "./Footer.module.scss";
import Accordion from "../../components/Accordian/Accordian";

import { ApiService } from "../../services/apiServices";
import {
  FooterHeading,
  FooterSections,
  Page,
  StorageEnums,
} from "../../enums/storageEnums";
import GlobalLoader from "../../atoms/GlobalLodaer/GlobalLoader";
import { APIResponse } from "../Home/Home";
import FooterNavigation from "../../components/FooterNavigation/FooterNavigation";
import { footerSchema } from "./footer.schema";
import FooterSocialLinks from "../../components/FooterSocialLinks/FooterSocialLinks";
import FooterDescription from "../../components/FooterDescription/FooterDescription";
import { getSignedUrl, uploadImage } from "../../constants/commonFunctions";
import { toast } from "react-toastify";
import FooterCopyright from "../../components/FooterCopyright/FooterCopyright";

const Header = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForPublish, setLoadingForPublish] = useState<boolean>(false);
  const [footerPageData, setFooterPageData] = useState<APIResponse[]>([]);
  const [isPublish, setIsPublish] = useState<boolean>(true);
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

  const apiService = new ApiService();
  const CloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const methods = useForm({
    resolver: yupResolver(footerSchema),
  });
  const { handleSubmit, formState } = methods;
  useEffect(() => {
    getFooterPageData();
  }, []);

  useEffect(() => {
    setIsFormEdited(formState.isDirty);
  }, [formState]);

  const getFooterPageData = async () => {
    const payload = {
      url: `pages/${Page.FOOTER}`,
      headerToken: localStorageUser.data.accessToken,
    };
    const response = await apiService.get(payload);
    const data = response?.data?.result;
    setFooterPageData(data);

    if (data.length) {
      setIsPublish(data[0].isPublish);
      const resetObject: { [key: string]: any } = {};
      data.forEach((item: any) => {
        resetObject[item.key] = item.content;
      });
      methods.reset(resetObject);
    } else {
      setIsPublish(true);
    }
  };

  const handleSave = async (data: FieldValues) => {
    const { footerSocialLogo1, footerSocialLogo2, footerSocialLogo3 } = data;
    const imagesToUpload = [
      { type: "footerSocialLogo1", image: footerSocialLogo1 },
      { type: "footerSocialLogo2", image: footerSocialLogo2 },
      { type: "footerSocialLogo3", image: footerSocialLogo3 },
    ];
    if (!footerPageData.length) {
      try {
        setLoading(true);

        // Generate signed URLs for each image
        const signedUrls = await Promise.all(
          imagesToUpload.map(async (imageObject) => {
            const { image } = imageObject;
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // DESCRIPTION SECTION
          {
            section: FooterSections.FOOTER_DESCRIPTION_SECTION,
            key: FooterHeading.FOOTER_DESCRIPTION,
            content: data[FooterHeading.FOOTER_DESCRIPTION],
            page: Page.FOOTER,
          },

          // NAVIGATION SECTION
          {
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE1,
            content: data[FooterHeading.FOOTER_TITLE1],
            page: Page.FOOTER,
          },

          {
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE2,
            content: data[FooterHeading.FOOTER_TITLE2],
            page: Page.FOOTER,
          },

          {
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE3,
            content: data[FooterHeading.FOOTER_TITLE3],
            page: Page.FOOTER,
          },

          {
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE4,
            content: data[FooterHeading.FOOTER_TITLE4],
            page: Page.FOOTER,
          },

          // SOCILA SECTION
          {
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_TITLE,
            content: data[FooterHeading.FOOTER_SOCIAL_TITLE],
            page: Page.FOOTER,
          },

          {
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_LOGO1,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO1
                )?.key || "",
            page: Page.FOOTER,
          },
          {
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_URL1,
            content: data[FooterHeading.FOOTER_SOCIAL_URL1],
            page: Page.FOOTER,
          },

          {
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_LOGO2,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO2
                )?.key || "",
            page: Page.FOOTER,
          },
          {
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_URL2,
            content: data[FooterHeading.FOOTER_SOCIAL_URL2],
            page: Page.FOOTER,
          },

          {
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_LOGO3,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO3
                )?.key || "",
            page: Page.FOOTER,
          },
          {
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_URL3,
            content: data[FooterHeading.FOOTER_SOCIAL_URL3],
            page: Page.FOOTER,
          },

          //COPYRIGHT SECTION
          {
            section: FooterSections.FOOTER_COPYRIGHT_SECTION,
            key: FooterHeading.FOOTER_COPYRIGHT_TITLE,
            content: data[FooterHeading.FOOTER_COPYRIGHT_TITLE],
            page: Page.FOOTER,
          },
        ];

        // Upload images
        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );

        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.FOOTER}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        methods.reset();
        getFooterPageData();

        toast.success("Data uploaded successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    } else {
      const nonStringImages = imagesToUpload.filter(
        (image) => typeof image.image !== "string"
      );

      try {
        setLoading(true);
        const signedUrls = await Promise.all(
          nonStringImages.map(async (imageObject) => {
            const { image } = imageObject;
            const imageKey = data[image];
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // DESCRIPTION section
          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_DESCRIPTION
            )?.id,
            section: FooterSections.FOOTER_DESCRIPTION_SECTION,
            key: FooterHeading.FOOTER_DESCRIPTION,
            content: data[FooterHeading.FOOTER_DESCRIPTION],
            page: Page.FOOTER,
          },

          // NAVIGATION SECTION
          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_TITLE1
            )?.id,
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE1,
            content: data[FooterHeading.FOOTER_TITLE1],
            page: Page.FOOTER,
          },

          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_TITLE2
            )?.id,
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE2,
            content: data[FooterHeading.FOOTER_TITLE2],
            page: Page.FOOTER,
          },

          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_TITLE3
            )?.id,
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE3,
            content: data[FooterHeading.FOOTER_TITLE3],
            page: Page.FOOTER,
          },

          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_TITLE4
            )?.id,
            section: FooterSections.FOOTER_NAVIGATION_SECTION,
            key: FooterHeading.FOOTER_TITLE4,
            content: data[FooterHeading.FOOTER_TITLE4],
            page: Page.FOOTER,
          },

          // SOCILA SECTION
          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_SOCIAL_TITLE
            )?.id,
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_TITLE,
            content: data[FooterHeading.FOOTER_SOCIAL_TITLE],
            page: Page.FOOTER,
          },

          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_SOCIAL_LOGO1
            )?.id,
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_LOGO1,
            content: signedUrls?.find(
              (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO1
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO1
                )?.key
              : footerPageData.find(
                  (item) => item.key === FooterHeading.FOOTER_SOCIAL_LOGO1
                )?.content || "",
            page: Page.FOOTER,
          },
          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_SOCIAL_URL1
            )?.id,
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_URL1,
            content: data[FooterHeading.FOOTER_SOCIAL_URL1],
            page: Page.FOOTER,
          },

          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_SOCIAL_LOGO2
            )?.id,
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_LOGO2,
            content: signedUrls?.find(
              (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO2
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO2
                )?.key
              : footerPageData.find(
                  (item) => item.key === FooterHeading.FOOTER_SOCIAL_LOGO2
                )?.content || "",
            page: Page.FOOTER,
          },
          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_SOCIAL_URL2
            )?.id,
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_URL2,
            content: data[FooterHeading.FOOTER_SOCIAL_URL2],
            page: Page.FOOTER,
          },

          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_SOCIAL_LOGO3
            )?.id,
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_LOGO3,
            content: signedUrls?.find(
              (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO3
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === FooterHeading.FOOTER_SOCIAL_LOGO3
                )?.key
              : footerPageData.find(
                  (item) => item.key === FooterHeading.FOOTER_SOCIAL_LOGO3
                )?.content || "",
            page: Page.FOOTER,
          },
          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_SOCIAL_URL3
            )?.id,
            section: FooterSections.FOOTER_SOCIAL_SECTION,
            key: FooterHeading.FOOTER_SOCIAL_URL3,
            content: data[FooterHeading.FOOTER_SOCIAL_URL3],
            page: Page.FOOTER,
          },

          //COPYRIGHT SECTION
          {
            id: footerPageData.find(
              (item) => item.key === FooterHeading.FOOTER_COPYRIGHT_TITLE
            )?.id,
            section: FooterSections.FOOTER_COPYRIGHT_SECTION,
            key: FooterHeading.FOOTER_COPYRIGHT_TITLE,
            content: data[FooterHeading.FOOTER_COPYRIGHT_TITLE],
            page: Page.FOOTER,
          },
        ];

        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );
        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.FOOTER}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        getFooterPageData();
        toast.success("Data updated successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePublish = async () => {
    setLoadingForPublish(true);
    try {
      const payload = {
        url: `publish/pages/${Page.FOOTER}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.post(payload);
      toast.success("Published successfully");
      getFooterPageData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoadingForPublish(false);
    }
  };

  return (
    <div className={`${styles.home}`}>
      <div
        className={`flex align-center justify-between ${styles.home__header}`}
      >
        <h2>Footer</h2>
        <div className={`flex align-center ${styles.home__button}`}>
          <Button
            label="Save"
            type="submit"
            buttonClass="md"
            loading={loading}
            onClick={handleSubmit(handleSave)}
            disabled={loading || !isFormEdited}
          />

          <Button
            label="Publish"
            type="submit"
            buttonClass="md"
            loading={loadingForPublish}
            disabled={isPublish}
            onClick={handlePublish}
          />
        </div>
      </div>

      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          event.preventDefault()
        }
        noValidate
      >
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="Description ">
              <FooterDescription />
            </Accordion>
          </FormProvider>
        </div>
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="Navigation ">
              <FooterNavigation />
            </Accordion>
          </FormProvider>
        </div>
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="Social Links">
              <FooterSocialLinks />
            </Accordion>
          </FormProvider>
        </div>
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="Copyright">
              <FooterCopyright />
            </Accordion>
          </FormProvider>
        </div>
      </form>
      {loading && <GlobalLoader />}
    </div>
  );
};

export default Header;
