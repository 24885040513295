import React from "react";
import InputMask from "react-input-mask";
import styles from "./MaskedInput.module.scss";

const MaskedInput = ({
  register,
  name,
  onChange,
  errors,
  label,
  placeholder,
  mask = "999-999-9999",
  maskChar = undefined,
}: any) => {
  return (
    <>
       <div className={`${styles.inputMask}`}>
        <label className="label">{label}</label>
        <div className={`${styles.inputMask__input}`}>
        <InputMask
          mask={mask}
          {...register(name)}
          errors={errors}
          maskChar={maskChar}
          className={errors?.[name] ? `inputError` : ``}
          placeholder={placeholder}
        ></InputMask>
        </div>
        {errors?.[name] && (
          <span  className={`${styles.inputMask__error}`}> {errors?.[name]?.message} </span>
        )}
      </div>
    </>
  );
};

export default MaskedInput;
