import React, { useEffect } from "react";
import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";
import FileInput from "../../atoms/FileInput/FileInput";
import HomeCard from "./HomeCard";
import styles from "./HomeCardsSection.module.scss";

const HomeCardsSection = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        label="Title*"
        placeholder="Title"
        register={register}
        registerName="cardSectionMainTitle"
        errorMessage={errors?.cardSectionMainTitle?.message}
      />
      <Input
        type="textarea"
        label="Description*"
        placeholder="Description"
        register={register}
        registerName="cardSectionMainDescription"
        errorMessage={errors?.cardSectionMainDescription?.message}
      />
      <h2>Cards Section</h2>
      <div className={` ${styles.cards}`}>
        {[1, 2, 3].map((cardNumber) => (
          <div key={cardNumber} className={` ${styles.cards__card}`}>
            <HomeCard
              getValues={getValues}
              key={cardNumber}
              cardNumber={cardNumber}
              errors={errors}
              register={register}
              control={control}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeCardsSection;
