import RouterPath from "./routes/RouterPath";
import interceptor from "./interceptors/Interceptors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interceptor();
function App() {
  return (
    <div>
      {/* <Header /> */}
      <RouterPath />
      <ToastContainer />
    </div>
  );
}

export default App;
