import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../atoms/Button/Button";
import { contactUsSchema } from "./ContactUs.schema";
import styles from "./ContactUs.module.scss";
import Accordion from "../../components/Accordian/Accordian";
import { ApiService } from "../../services/apiServices";
import {
  ContactUsHeading,
  ContactUsSections,
  Page,
  StorageEnums,
} from "../../enums/storageEnums";
import { toast } from "react-toastify";
import GlobalLoader from "../../atoms/GlobalLodaer/GlobalLoader";
import { getSignedUrl, uploadImage } from "../../constants/commonFunctions";
import ContactUsBannerSection from "../../components/ContactUsBannerSection/ContactUsBannerSection";
import ContactUsCardsSection from "../../components/ContactUsCardsSection/ContactUsCardsSection";
import ContactUsFormSection from "../../components/ContactUsFormSection/ContactUsFormSection";

export interface APIResponse {
  id: string;
  section: string;
  key: string;
  content: string;
  page: string;
  order: number;
  isPublish: boolean;
}

const ContactUs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForPublish, setLoadingForPublish] = useState<boolean>(false);
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
  const [contactUsPageData, setContactUsPageData] = useState<APIResponse[]>([]);
  const [isPublish, setIsPublish] = useState<boolean>(true);
  const apiService = new ApiService();
  const localStorageUser = JSON.parse(
    localStorage.getItem(StorageEnums.CREDENTIALS) || '{"accessToken": ""}'
  );

  const methods = useForm({
    resolver: yupResolver(contactUsSchema),
  });
  const { handleSubmit, formState, watch } = methods;

  const CloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    // Listen for changes in any form field
    setIsFormEdited(formState.isDirty);
  }, [formState]);
  const getContactUsPageData = async () => {
    const payload = {
      url: `pages/${Page.CONTACT_US}`,
      headerToken: localStorageUser.data.accessToken,
    };
    const response = await apiService.get(payload);
    const data = response?.data?.result;
    setContactUsPageData(data);

    if (data.length) {
      setIsPublish(data[0].isPublish);
      const resetObject: { [key: string]: any } = {};
      data.forEach((item: any) => {
        resetObject[item.key] = item.content;
      });
      methods.reset(resetObject);
    } else {
      setIsPublish(true);
    }
  };

  useEffect(() => {
    getContactUsPageData();
  }, []);

  const handleSave = async (data: FieldValues) => {
    const { contactUsBannerImage, card1Icon, card2Icon, card3Icon } = data;

    const imagesToUpload = [
      { type: "contactUsBannerImage", image: contactUsBannerImage },
      { type: "card1Icon", image: card1Icon },
      { type: "card2Icon", image: card2Icon },
      { type: "card3Icon", image: card3Icon },
    ];

    if (!contactUsPageData.length) {
      try {
        setLoading(true);

        // Generate signed URLs for each image
        const signedUrls = await Promise.all(
          imagesToUpload.map(async (imageObject) => {
            const { image } = imageObject;
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // Banner section
          {
            section: ContactUsSections.CONTACT_US_BANNER_SECTION,
            key: ContactUsHeading.CONTACT_US_BANNER_TITLE,
            content: data[ContactUsHeading.CONTACT_US_BANNER_TITLE],
            page: Page.CONTACT_US,
          },
          {
            section: ContactUsSections.CONTACT_US_BANNER_SECTION,
            key: ContactUsHeading.CONTACT_US_BANNER_DESCRIPTIION,
            content: data[ContactUsHeading.CONTACT_US_BANNER_DESCRIPTIION],
            page: Page.CONTACT_US,
          },
          {
            section: ContactUsSections.CONTACT_US_BANNER_SECTION,
            key: ContactUsHeading.CONTACT_US_BANNER_IMAGE,
            content:
              CloudFrontURL +
                signedUrls.find(
                  (url) => url.type === ContactUsHeading.CONTACT_US_BANNER_IMAGE
                )?.key || "",
            page: Page.CONTACT_US,
          },

          // Section 2 title and description
          {
            section: ContactUsSections.CONTACT_US_CARD_SECTION,
            key: ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_TITLE,
            content: data[ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_TITLE],
            page: Page.CONTACT_US,
          },
          {
            section: ContactUsSections.CONTACT_US_CARD_SECTION,
            key: ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_DESCRIPTION,
            content:
              data[ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_DESCRIPTION],
            page: Page.CONTACT_US,
          },
          // Card sections
          ...[1, 2, 3].flatMap((index) => [
            {
              section: ContactUsSections.CONTACT_US_CARD_SECTION,
              key: `card${index}Title`,
              content: data[`card${index}Title`],
              page: Page.CONTACT_US,
            },
            {
              section: ContactUsSections.CONTACT_US_CARD_SECTION,
              key: `card${index}Description`,
              content: data[`card${index}Description`],
              page: Page.CONTACT_US,
            },
            {
              section: ContactUsSections.CONTACT_US_CARD_SECTION,
              key: `card${index}Icon`,
              content:
                CloudFrontURL +
                  signedUrls.find((url) => url.type === `card${index}Icon`)
                    ?.key || "",
              page: Page.CONTACT_US,
            },
          ]),

          {
            section: ContactUsSections.CONTACT_US_FORM_SECTION,
            key: ContactUsHeading.CONTACT_US_FORM_TITLE,
            content: data[ContactUsHeading.CONTACT_US_FORM_TITLE],
            page: Page.CONTACT_US,
          },
          {
            section: ContactUsSections.CONTACT_US_FORM_SECTION,
            key: ContactUsHeading.CONTACT_US_FORM_SUBTITLE,
            content: data[ContactUsHeading.CONTACT_US_FORM_SUBTITLE],
            page: Page.CONTACT_US,
          },
        ];

        // Upload images
        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );

        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.CONTACT_US}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        methods.reset();
        getContactUsPageData();

        toast.success("Data uploaded successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    } else {
      const nonStringImages = imagesToUpload.filter(
        (image) => typeof image.image !== "string"
      );

      try {
        setLoading(true);
        const signedUrls = await Promise.all(
          nonStringImages.map(async (imageObject) => {
            const { image } = imageObject;
            const imageKey = data[image];
            const imageInfo = {
              fileName: image.name,
              contentType: image.type,
            };
            const res = await getSignedUrl(imageInfo);
            return {
              type: imageObject.type,
              uploadUrl: res.data.uploadUrl,
              key: res.data.key,
            };
          })
        );

        // Prepare data to upload
        const dataToUpload: any[] = [
          // Banner section

          {
            id: contactUsPageData.find(
              (item) => item.key === ContactUsHeading.CONTACT_US_BANNER_TITLE
            )?.id,
            section: ContactUsSections.CONTACT_US_BANNER_SECTION,
            key: ContactUsHeading.CONTACT_US_BANNER_TITLE,
            content: data[ContactUsHeading.CONTACT_US_BANNER_TITLE],
            page: Page.CONTACT_US,
          },
          {
            id: contactUsPageData.find(
              (item) =>
                item.key === ContactUsHeading.CONTACT_US_BANNER_DESCRIPTIION
            )?.id,
            section: ContactUsSections.CONTACT_US_BANNER_SECTION,
            key: ContactUsHeading.CONTACT_US_BANNER_DESCRIPTIION,
            content: data[ContactUsHeading.CONTACT_US_BANNER_DESCRIPTIION],
            page: Page.CONTACT_US,
          },
          {
            id: contactUsPageData.find(
              (item) => item.key === ContactUsHeading.CONTACT_US_BANNER_IMAGE
            )?.id,
            section: ContactUsSections.CONTACT_US_BANNER_SECTION,
            key: ContactUsHeading.CONTACT_US_BANNER_IMAGE,
            content: signedUrls?.find(
              (url) => url.type === ContactUsHeading.CONTACT_US_BANNER_IMAGE
            )
              ? CloudFrontURL +
                signedUrls?.find(
                  (url) => url.type === ContactUsHeading.CONTACT_US_BANNER_IMAGE
                )?.key
              : contactUsPageData.find(
                  (item) =>
                    item.key === ContactUsHeading.CONTACT_US_BANNER_IMAGE
                )?.content || "",
            page: Page.CONTACT_US,
          },

          // Section 2 title and description

          {
            id: contactUsPageData.find(
              (item) =>
                item.key === ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_TITLE
            )?.id,
            section: ContactUsSections.CONTACT_US_CARD_SECTION,
            key: ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_TITLE,
            content: data[ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_TITLE],
            page: Page.CONTACT_US,
          },
          {
            id: contactUsPageData.find(
              (item) =>
                item.key ===
                ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_DESCRIPTION
            )?.id,
            section: ContactUsSections.CONTACT_US_CARD_SECTION,
            key: ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_DESCRIPTION,
            content:
              data[ContactUsHeading.CONTACT_US_CARD_SECTION_MAIN_DESCRIPTION],
            page: Page.CONTACT_US,
          },
          // Card sections
          ...[1, 2, 3].flatMap((index) => [
            {
              id: contactUsPageData.find(
                (item) => item.key === `card${index}Title`
              )?.id,
              section: ContactUsSections.CONTACT_US_CARD_SECTION,
              key: `card${index}Title`,
              content: data[`card${index}Title`],
              page: Page.CONTACT_US,
            },
            {
              id: contactUsPageData.find(
                (item) => item.key === `card${index}Description`
              )?.id,
              section: ContactUsSections.CONTACT_US_CARD_SECTION,
              key: `card${index}Description`,
              content: data[`card${index}Description`],
              page: Page.CONTACT_US,
            },
            {
              id: contactUsPageData.find(
                (item) => item.key === `card${index}Icon`
              )?.id,
              section: ContactUsSections.CONTACT_US_CARD_SECTION,
              key: `card${index}Icon`,
              content: signedUrls.find((url) => url.type === `card${index}Icon`)
                ? CloudFrontURL +
                  signedUrls?.find((url) => url.type === `card${index}Icon`)
                    ?.key
                : contactUsPageData.find(
                    (item) => item.key === `card${index}Icon`
                  )?.content || "",
              page: Page.CONTACT_US,
            },
          ]),

          {
            id: contactUsPageData.find(
              (item) => item.key === ContactUsHeading.CONTACT_US_FORM_TITLE
            )?.id,
            section: ContactUsSections.CONTACT_US_FORM_SECTION,
            key: ContactUsHeading.CONTACT_US_FORM_TITLE,
            content: data[ContactUsHeading.CONTACT_US_FORM_TITLE],
            page: Page.CONTACT_US,
          },
          {
            id: contactUsPageData.find(
              (item) => item.key === ContactUsHeading.CONTACT_US_FORM_SUBTITLE
            )?.id,
            section: ContactUsSections.CONTACT_US_FORM_SECTION,
            key: ContactUsHeading.CONTACT_US_FORM_SUBTITLE,
            content: data[ContactUsHeading.CONTACT_US_FORM_SUBTITLE],
            page: Page.CONTACT_US,
          },
        ];

        await Promise.all(
          signedUrls.map(async (signedUrl) => {
            if (signedUrl?.uploadUrl) {
              const imageObject = imagesToUpload.find(
                (image) => image.type === signedUrl.type
              );
              if (imageObject) {
                await uploadImage(imageObject.image, signedUrl.uploadUrl);
              }
            }
          })
        );
        const payload = {
          data: { sections: dataToUpload },
          url: `pages/${Page.CONTACT_US}`,
          headerToken: localStorageUser.data.accessToken,
        };
        await apiService.post(payload);
        getContactUsPageData();
        toast.success("Data updated successfully");
      } catch (error: any) {
        console.error(error, "Error occurred while uploading images");
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePublish = async () => {
    setLoadingForPublish(true);
    try {
      const payload = {
        url: `publish/pages/${Page.CONTACT_US}`,
        headerToken: localStorageUser.data.accessToken,
      };
      await apiService.post(payload);
      toast.success("Published successfully");
      getContactUsPageData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoadingForPublish(false);
    }
  };

  return (
    <div className={`${styles.home}`}>
      <div
        className={`flex align-center justify-between ${styles.home__header}`}
      >
        <h2>Contact Us</h2>
        <div className={`flex align-center ${styles.home__button}`}>
          <Button
            label="Save"
            type="submit"
            buttonClass="md"
            loading={loading}
            disabled={loading || !isFormEdited}
            onClick={handleSubmit(handleSave)}
          />

          <Button
            label="Publish"
            type="submit"
            buttonClass="md"
            loading={loadingForPublish}
            disabled={isPublish}
            onClick={handlePublish}
          />
        </div>
      </div>

      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          event.preventDefault()
        }
        noValidate
      >
        <div className={`flex-column ${styles.home__form}`}>
          <FormProvider {...methods}>
            <Accordion title="Contact Us Banner">
              <ContactUsBannerSection />
            </Accordion>
          </FormProvider>

          <FormProvider {...methods}>
            <Accordion title="Contact Information">
              <ContactUsCardsSection />
            </Accordion>
          </FormProvider>

          <FormProvider {...methods}>
            <Accordion title="Contact Form">
              <ContactUsFormSection />
            </Accordion>
          </FormProvider>
        </div>
      </form>
      {loading && <GlobalLoader />}
    </div>
  );
};

export default ContactUs;
