import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";

const HAQSection = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="HAQTitle"
        errorMessage={errors?.HAQTitle?.message}
      />
      <Input
        type="text"
        placeholder="Description"
        label="Description*"
        register={register}
        registerName="HAQDescription"
        errorMessage={errors?.HAQDescription?.message}
      />
      <Input
        type="text"
        placeholder="Button Title"
        label="Button Title*"
        register={register}
        registerName="HAQButton"
        errorMessage={errors?.HAQButton?.message}
      />
    </div>
  );
};

export default HAQSection;
