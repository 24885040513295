import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";

const ContactUsFormSection = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <Input
        type="text"
        label="Title*"
        placeholder="Title"
        register={register}
        registerName="contactFormTitle"
        errorMessage={errors?.contactFormTitle?.message}
      />
      <Input
        type="text"
        label="Subtitle*"
        placeholder="Subtitle"
        register={register}
        registerName="contactFormSubtitle"
        errorMessage={errors?.contactFormSubtitle?.message}
      />
    </div>
  );
};

export default ContactUsFormSection;
