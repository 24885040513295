import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import style from "./TagInput.module.scss";
import cross from "../../assets/images/cross-small.svg";

interface AppState {
  items: { tag: string }[];
  value: string;
  error: string | null;
}

interface TagInputProps {
  name: string;
  control: any;
  onTagsChange: any;
  placeholder: string;
  error: string | undefined;
  label?: string;
}

const TagInput: React.FC<TagInputProps> = ({
  name,
  control,
  onTagsChange,
  placeholder,
  error,
  label,
}) => {
  const [state, setState] = useState<AppState>({
    items: [],
    value: "",
    error: error as string,
  });
  const { trigger, reset, setValue, clearErrors, getValues, watch } =
    useFormContext();
  useEffect(() => {
    if (getValues(name)) clearErrors(name);
  }, [getValues(name)]);

  useEffect(() => {
    const watchedItems = watch(name);
    if (watchedItems && Array.isArray(watchedItems)) {
      setState((prevState) => ({
        ...prevState,
        items: watchedItems,
      }));
    }
  }, [watch(name)]);

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      evt.key === "Backspace" &&
      state.value === "" &&
      state.items.length > 0
    ) {
      // Delete the last tag if Backspace is pressed and input field is empty
      const updatedItems = [...state.items];
      updatedItems.pop();
      if (updatedItems.length == 0) {
        setValue(name, null);
        trigger(name);
      }

      onTagsChange(updatedItems);
      setValue(name, updatedItems);
      setState({
        ...state,
        items: updatedItems,
      });
    }
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      const trimmedValue = state.value.trim();
      if (trimmedValue && isValid(trimmedValue)) {
        const updatedTags = [...state.items, { tag: state.value }];
        onTagsChange(updatedTags);
        setValue(name, updatedTags);

        setState({
          ...state,
          items: updatedTags,
          value: "",
          error: null,
        });
      }
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setState({
      ...state,
      value: value,
      error: value.trim() === "" ? "" : null,
    });
  };

  const handleDelete = (tag: string) => {
    const updatedItems = state.items.filter((item) => item.tag !== tag);
    setState({
      ...state,
      items: updatedItems,
    });
    if (updatedItems.length == 0) {
      setValue(name, null);
      trigger(name);
    }
    onTagsChange(updatedItems);
    setValue(name, updatedItems);
  };

  const isValid = (tag: string): boolean => {
    let error = null;

    if (isInList(tag)) {
      error = `${tag} has already been added.`;
    }

    if (error) {
      setState({ ...state, error });
      return false;
    }

    return true;
  };
  const isInList = (tag: string): boolean => {
    return state.items.some(
      (item) => item.tag.toLowerCase().trim() === tag.toLowerCase().trim()
    );
  };

  const handleBlur = () => {
    const trimmedValue = state.value.trim();
    if (trimmedValue && isValid(trimmedValue)) {
      const updatedTags = [...state.items, { tag: state.value }];
      onTagsChange(updatedTags);
      setValue(name, updatedTags);
      setState({
        ...state,
        items: updatedTags,
        value: "",
        error: null,
      });
    }
  };

  return (
    <div className={style.tagInput}>
      {label && <label>{label}</label>}
      <div className={style.tagInput__field}>
        {state?.items?.map((item, index) => (
          <div className={style.tagInput__button} key={index}>
            <cite>{item.tag}</cite>
            <span onClick={() => handleDelete(item.tag)}>
              <img src={cross} alt="cross" />
            </span>
          </div>
        ))}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <input
                className={"input" + (state.error ? " has-error" : "")}
                value={state.value}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  handleChange(e);
                  field.onChange(e);
                }}
                onBlur={handleBlur}
              />
            </>
          )}
        />
      </div>
      {state.error && !error && (
        <span className={style.tagInput__error}>{state.error}</span>
      )}
      {error && <span className={style.tagInput__error}>{error}</span>}
    </div>
  );
};

export default TagInput;
