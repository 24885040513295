import React, { useState } from "react";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { StorageService } from "../../services/storage.service";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import arrow from "../../assets/images/down-arrow.svg";
import userImage from "../../assets/images/logo.svg";
import Logo from "../Logo/Logo";
import menu from "../../assets/images/menu.svg";

const Header = ({ showNav, setShowNav }: { showNav: any; setShowNav: any }) => {
  const storageService = new StorageService();
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleLogout = () => {
    storageService.clearLocalStorage();
    navigate("/login");
  };

  return (
    <header className={`flex-wrap justify-end  ${styles.header}`}>
      <div className={` ${styles.header__logo}`}>
        <Logo logoBlack />
      </div>
      <div className={`flex align-center ${styles.header__menu}`}>
        <ul className="flex align-center">
          <li>
            <DropdownMenu menuState={setDropdownVisible}>
              <div
                className={`${styles.header__dropdown} ${
                  isDropdownVisible && styles.active
                }`}
              >
                <div
                  role="button"
                  onClick={toggleDropdown}
                  className={`flex align-center ${styles.header__user}`}
                >
                  <figure>
                    <img src={userImage} alt="user" />
                  </figure>
                  <span>
                    Admin <img src={arrow} alt="arrow" />
                  </span>
                </div>
                <ul
                  className="flex align-center"
                  onClick={() => setDropdownVisible(false)}
                >
                  <li role="button" onClick={handleLogout}>
                    Logout
                  </li>
                </ul>
              </div>
            </DropdownMenu>
          </li>
        </ul>
        <div
          role="button"
          className={` ${styles.header__toggle}`}
          onClick={() => setShowNav(true)}
        >
          <img src={menu} alt="menu" />
        </div>
      </div>
    </header>
  );
};

export default Header;
