import Input from "../../atoms/Input/Input";
import { useFormContext } from "react-hook-form";
import FileInput from "../../atoms/FileInput/FileInput";

const ContactUsBannerSection = () => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
  } = useFormContext();

  return (
    <div className="flex-column gap-20">
      <FileInput
        label="Banner Image*"
        register={register}
        registerName="contactUsBannerImage"
        disabled={false}
        accept="image/png,image/jpeg,image/jpg,image/svg+xml"
        control={control}
        errorMessage={errors?.contactUsBannerImage?.message}
        value={getValues("contactUsBannerImage") ?? ""}
      />
      <Input
        type="text"
        placeholder="Title"
        label="Title*"
        register={register}
        registerName="contactUsBannerTitle"
        errorMessage={errors?.contactUsBannerTitle?.message}
      />
      <Input
        type="textarea"
        label="Description*"
        placeholder="Description"
        register={register}
        registerName="contactUsBannerDescription"
        errorMessage={errors?.contactUsBannerDescription?.message}
      />
    </div>
  );
};

export default ContactUsBannerSection;
