import React from "react";
import style from "./Skeleton.module.scss";
export interface SkeletonProps {
  margin?: string;
  height?: string;
  width?: string;
  color?: string;
  radius?: string;
}

const Skeleton = ({
  margin = "0",
  width = "100%",
  radius = "5px",
  height = "10px",
  color = "#ebebeb",
}: SkeletonProps) => {
  return (
    <div
      className={style.skeleton}
      style={{
        width: width,
        margin: margin,
        height: height,
        borderRadius: radius,
        backgroundColor: color,
      }}
    ></div>
  );
};
export default Skeleton;
